@use "../global/" as g;

.o-page_title {
  height: 140px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include g.m(tour){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/tour/kv.png);
    }
  }
  @include g.m(tour_top){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/tour_top/kv.png);
    }
  }
  @include g.m(company){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/company/kv.png);
    }
  }
  @include g.m(media){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/media/kv.png);
    }
  }
  @include g.m(qa){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/qa/kv.png);
    }
  }
  @include g.m(feature){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/feature/kv.png);
    }
  }
  @include g.m(brain){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/brain/kv.png);
    }
  }
  @include g.m(tokyo_plus){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/tokyo_plus/kv.png);
    }
    .o-page_title__content p{
      max-width: 320px;
      @include g.mq{
        max-width: 440px;
      }
    }
  }
  @include g.m(about_tour){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/about_tour/kv.png);
    }
  }
  @include g.m(news){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/news/kv.png);
    }
    .o-page_title__content p{
      max-width: 190px;
      @include g.mq{
        max-width: 214px;
      }
    }
  }
  @include g.m(rule){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/rule/kv.png);
    }
    .o-page_title__content p{
      max-width: 250px;
      @include g.mq{
        max-width: 290px;
      }
    }
  }
  @include g.m(cookie){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/cookie/kv.png);
    }
    .o-page_title__content p{
      max-width: 250px;
      @include g.mq{
        max-width: 290px;
      }
    }
  }
  @include g.m(terms){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/terms/kv.png);
    }
  }
  @include g.m(privacy){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/privacy/kv.png);
    }
  }
  @include g.m(contact){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/contact/kv.png);
    }
  }
  @include g.m(itswellness){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/itswellness/kv.png);
    }
    .o-page_title__content p{
      height: 50px;
      @include g.mq{
        height: 40px;
        max-width: 440px;
      }
      span{
        display: block;
        font-size: 12px;
        @include g.mq{
          display: inline;
          font-size: 14px;
        }
      }
    }
  }
  @include g.m(cancelpolicy){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/cancelpolicy/kv.png);
    }
  }
  @include g.m(about){
    background-color: #F2F8F2;
    @include g.mq_m{
      height: 240px;
      background-image: url(../images/about/kv.png);
    }
  }
  @include g.e(content){
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%); /* Safari用 */
    transform: translate(-50%, -50%);
    @include g.mq_m{
      width: 48%;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
    }
    p{
      display: -webkit-flex; /* Safari用 */
      display: flex;
      -webkit-justify-content: center; /* Safari用 */
      justify-content: center;
      -webkit-align-items: center; /* Safari用 */
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      max-width: 220px;
      margin: 0 auto 15px;
      line-height: 1;
      height: 30px;
      font-size: 16px;
      position: relative;
      border-radius: 20px;
      background: #fff;
      color: #4CB951;
      font-weight: bold;
      padding-top: 5px;
      @include g.mq{
        max-width: 250px;
        height: 40px;
        font-size: 20px;
      }
      &:after{
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-top: 6px solid #fff;
        border-bottom: 0;
        position: absolute;
        bottom: -6px;
        left: 50%;
        -webkit-transform: translateX(-50%); /* Safari用 */
        transform: translateX(-50%);
      }
    }
    h2{
      text-align: center;
      color: g.$c_grn;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.5;
      @include g.mq{
        font-size: 45px;
      }
    }
  }
}
