@use "../global/" as g;

.p-news{
  padding-top: 40px;
  padding-bottom: 40px;
  @include g.mq{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include g.m(feature){
    .p-news__archive ul{
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      li{
        @include g.mq{
          width: 48%;
          margin-right: 4%;
        }
        a{
          padding: 0;
        }
        dl{
          dd{
            border-radius: 12px 12px 0 0;
            overflow: hidden;
            img{
              height: 205px;
              object-fit: cover;
            }
          }
          dt{
            padding: 20px;
            font-size: 12px;
            @include g.mq{
              margin-bottom: 0;
              font-size: 14px;
              padding: 25px 30px 30px;
            }
            .is-content{
              line-height: 1.7;
              color: g.$c_black;
              font-weight: normal;
              margin-bottom: 0;
              padding: 0;
            }
            h3{
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 10px;
              @include g.mq{
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  @include g.e(single){
    @include g.e(link){
      display: -webkit-flex; /* Safari用 */
      display: flex;
      -webkit-justify-content: center; /* Safari用 */
      justify-content: center;
      -webkit-align-items: center; /* Safari用 */
      align-items: center;
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
      a{
        width: 48%;
        background: #F5F5F5;
        border-radius: 6px;
        padding: 20px;
        color: g.$c_ore;
        font-weight: bold;
        position: relative;
        &:nth-child(2){
          margin-left: 4%;
        }
        @include g.m(next-link){
          padding-right: 30px;
          &:before{
            content: "前の記事";
          }
          &:after{
            right: -10px;
            @include g.mq{
              right: -40px;
            }
          }
        }
        @include g.m(prev-link){
          padding-left: 30px;
          &:before{
            content: "次の記事";
          }
          &:after{
            left: -10px;
            background-image: url(../images/common/arw_prev.png);
            @include g.mq{
              left: -40px;
            }
          }
        }
        &:after{
          content: "";
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          width: 32px;
          height: 32px;
          background: url(../images/common/arw_next.png) center no-repeat g.$c_ore;
          background-size: 12px;
          border-radius: 50%;
          z-index: 1;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%); /* Safari用 */
          transform: translateY(-50%);
          @include g.mq{
            background-size: 20px;
            width: 62px;
            height: 62px;
          }
        }
        &:before{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          max-width: 120px;
          margin: 0 auto 10px;
          line-height: 1;
          height: 27px;
          font-size: 14px;
          color: g.$c_ore;
          border-radius: 20px;
          background: #fff;
          font-weight: bold;
          padding-top: 5px;
          @include g.mq{
            max-width: 170px;
          }
        }
      }
      .is-prev-link{

      }
    }
    @include g.e(content){
      background: #F5F5F5;
      border-radius: 12px;
      padding: 20px;
      font-size: 12px;
      line-height: 1.7;
      margin-bottom: 30px;
      @include g.mq{
        font-size: 14px;
        margin-bottom: 40px;
      }
      @include g.mq_m{
        padding: 50px 30px 20px;
      }
      p{
        margin-bottom: 20px;
      }
    }
    @include g.e(title){
      dl{
        background: #F2F8F2;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 30px;
        @include g.mq{
          margin-bottom: 40px;
        }
        dd img{
          max-height: 225px;
          object-fit: cover;
        }
        dt{
          padding: 20px 20px 15px;
          @include g.mq_m{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            padding-left: 40px;
            padding-right: 40px;
          }
          p{
            font-size: 12px;
            color: #969696;
            background: url(../images/common/icon_time.png) left center no-repeat;
            background-size: 18px;
            padding-left: 22px;
            padding-top: 5px;
            @include g.mq{
              font-size: 14px;
            }
            @include g.mq_m{
              width: 45%;
              padding-left: 20px;
            }
          }
          h2{
            font-weight: bold;
            font-size: 18px;
            color: g.$c_grn;
            @include g.mq_m{
              text-align: center;
              width: 55%;
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  @include g.e(pagination){
    margin-top: 40px;
  }
  @include g.e(archive){
    > ul >li{
      box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
      border-radius: 12px;
      background: #fff;
      margin-bottom: 20px;
      @include g.mq{
        margin-bottom: 30px;
      }
      > a{
        display: block;
        padding: 20px 20px 15px;
        > dl{
          > dt{
            font-size: 16px;
            line-height: 1.2;
            font-weight: bold;
            color: g.$c_grn;
            margin-bottom: 10px;
            @include g.mq{
              margin-bottom: 15px;
            }
          }
          > dd{
            .is-data{
              font-size: 12px;
              color: #969696;
              background: url(../images/common/icon_time.png) left center no-repeat;
              background-size: 18px;
              padding-left: 22px;
              padding-top: 5px;
              @include g.mq{
                font-size: 14px;
              }
            }
            .is-content{
              margin-bottom: 15px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}
