@use "../global/" as g;

.l-footer{
  background: #F5F5F5;
  padding-top: 30px;
  padding-bottom: 25px;
  @include g.mq{
    padding-top: 50px;
  }
  @include g.m(en){
    .l-footer__content__link{
      @include g.mq{
        padding-left: 50px;
        padding-right: 50px;
      }
      ul{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        li:nth-child(2){
          margin-left: 20px;
        }
      }
    }
    .l-footer__content__list{
      display: block;
      @include g.mq{
        padding-left: 50px;
        padding-right: 50px;
      }
      > ul{
        @include g.mq{
          width: 100%;
          padding-right: 0;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          > li a{
            margin-top: 0;
          }
        }
      }
    }
  }
  @include g.e(content){
    background: #fff;
    padding: 30px 20px;
    border-radius: 12px;
    margin-bottom: 20px;
    @include g.e(link){
      margin-top: 20px;
      ul li:first-child{
        max-width: 159px;
      }
    }
    @include g.e(list){
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      > ul{
        margin-bottom: 20px;
        @include g.mq{
          margin-bottom: 0;
          width: 27%;
          padding-right: 5px;
          &:nth-child(2){
            width: 23%;
          }
          &:nth-child(3){
            width: 25%;
          }
          &:last-child{
            width: 25%;
          }
        }
        > li{
          &:first-child a{
            margin-top: 0;
          }
          a{
            color: g.$c_grn;
            font-size: 13px;
            display: inline-block;
            margin-top: 10px;
            font-weight: bold;
            @include g.mq{
              margin-top: 20px;
              font-size: 16px;
            }
          }
          ul li a{
            color: g.$c_black;
            font-size: 11px;
            margin-top: 5px;
            @include g.mq{
              margin-top: 8px;
              font-size: 14px;
            }
          }
        }
      }
    }
    @include g.e(logo){
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      text-align: center;
      margin-bottom: 20px;
      @include g.mq{
        padding-bottom: 20px;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
      }
      ul{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        margin-top: 10px;
        @include g.mq{
          margin-top: 0;
          margin-left: 25px;
        }
        li{
          margin-right: 20px;
          max-width: 46px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  @include g.e(copy){
    display: -webkit-flex; /* Safari用 */
    display: flex;
    -webkit-justify-content: center; /* Safari用 */
    justify-content: center;
    -webkit-align-items: center; /* Safari用 */
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
    font-size: 12px;
    color: #969696;
  }
}
