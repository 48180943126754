@use "../global/" as g;

.p-itswellness{
  padding-bottom: 10px;
  @include g.m(en){
    .p-itswellness__work{
      p a{
        color: g.$c_ore;
        text-decoration: underline;
      }
      .o-title.is-last{
        transform: translateY(0);
        margin-top: 30px;
        @include g.mq{
          margin-top: 50px;
        }
      }
    }
    .p-itswellness__member .o-title p{
      max-width: 240px;
      @include g.mq{
        max-width: 300px;
      }
    }
    .p-itswellness__work ul li dl dt h3{
      height: inherit;
      min-height: 30px;
      font-size: 14px;
      @include g.mq{
        font-size: 16px;
      }
    }
    .p-itswellness__member__content .is-slide h3{
      height: inherit;
      min-height: 40px;
      padding-left: 42px;
      padding-right: 5px;
    }
  }
  @include g.m(brain){
    padding-bottom: 0;
  }
  @include g.m(about_tour){
    .o-page_link ul li p{
      max-width: 230px;
      @include g.mq{
        max-width: 280px;
      }
    }
    .p-itswellness__member{
      background: #F8F5D4;
      .p-itswellness__member__content{
        margin-top: 0;
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .is-slide{
          margin: 0 0 30px;
          @include g.mq{
            margin-bottom: 40px;
            width: 48%;
            &:nth-child(2n){
              margin-left: 4%;
            }
          }
        }
      }
    }
    .p-itswellness__read{
      height: auto;
      padding-bottom: 20px;
      @include g.mq{
        padding-bottom: 0;
        height: 270px;
        h2{
          padding-top: 60px;
        }
      }
      img{
        display: block;
        margin: 20px auto;
        @include g.mq{
          margin: 0;
          position: absolute;
          right: 0;
          top: 20px;
        }
      }
    }
  }
  .o-page_link{
    h2{
      color: g.$c_grn;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
      @include g.mq{
        font-size: 28px;
      }
    }
  }
  .o-title{
    transform: translateY(-15px);
    margin-bottom: 0;
    @include g.mq{
      transform: translateY(-24px);
    }
    h2{
      @include g.mq{
        margin-top: 25px;
      }
    }
  }
  @include g.e(link){
    background: #DAF7DA;
    padding-top: 40px;
    padding-bottom: 30px;
    @include g.mq{
      padding-top: 60px;
      padding-bottom: 50px;
    }
    .o-btn{
      margin-bottom: 20px;
    }
    ul{
      max-width: 960px;
      margin: 0 auto;
      text-align: center;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        li{
          width: 33.3%;
        }
      }
    }
  }
  @include g.e(list){
    padding-bottom: 50px;
    background: #DAF7DA;
    @include g.mq{
      padding-bottom: 60px;
    }
    @include g.m(archive){
      background: #F2F8F2;
      .p-itswellness__list__content > ul > li{
        background: #fff;
        dl{
          dd{
            padding: 0 20px 20px;
            @include g.mq{
              padding: 60px 50px 20px 0;
              width: 51%;
            }
          }
          dt{
            @include g.mq{
              width: 49%;
              padding-right: 25px;
            }
            .is-slide{
              background: #F5F5F5;
              border-radius: 12px;
              padding: 20px 20px 28px;
              margin-bottom: 0;
              @include g.mq{
                margin-left: 15px;
              }
              .slick-prev{
                transform: translate(0);
                top: auto;
                bottom: 8px;
                left: 15px;
                width: 37px;
                height: 37px;
                background: url(../images/common/arw_prev.png) center no-repeat g.$c_ore;
                background-size: 15px;
                border-radius: 50%;
                z-index: 1;
                &:before{
                  display: none;
                }
              }
              .slick-next{
                transform: translate(0);
                top: auto;
                bottom: 8px;
                right: 15px;
                width: 37px;
                height: 37px;
                background: url(../images/common/arw_next.png) center no-repeat g.$c_ore;
                background-size: 15px;
                border-radius: 50%;
                z-index: 1;
                &:before{
                  display: none;
                }
              }
              .slick-dots{
                position: relative;
                bottom: -8px;
                li{
                  width: 10px;
                  height: 10px;
                  &.slick-active button:before{
                    color: g.$c_ore;
                  }
                  button{
                    box-shadow: none;
                    width: 10px;
                    height: 10px;
                    padding: 0;
                    &:before{
                      opacity: 1;
                      color: #CCCCCC;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @include g.m(about){
      background: #F2F8F2;
      padding-top: 20px;
      .o-title{
        transform: translateY(0);
        margin-bottom: 15px;
        @include g.mq{
          transform: translateY(0);
          margin-bottom: 30px;
        }
        h2{
          @include g.mq{
            margin-top: 15px;
          }
        }
      }
      .p-itswellness__list__content > ul > li{
        background: #fff;
        dl{
          dd{
            @include g.mq{
              width: 41%;
              padding: 0;
            }
          }
          dt{
            padding-top: 45px;
            span{
              height: 28px;
              font-size: 12px;
            }
            h3{
              font-size: 14px;
              margin-bottom: 5px;
              padding-left: 0;
              @include g.mq{
                font-size: 18px;
              }
              &:before{
                content: "";
                display: inline-block;
                margin-right: 8px;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                background: url(../images/common/icon_check.png) no-repeat;
                background-size: 100%;
              }
            }
          }
        }
      }
    }
    @include g.e(content){
      > ul{
        @include g.m(btn){
          max-width: 580px;
          margin-left: auto;
          margin-right: auto;
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-top: 40px;
          }
          > li{
            background: transparent !important;
            margin-bottom: 0;
            @include g.mq{
              width: 48%;
            }
            &:nth-child(2n){
              margin-top: 20px;
              @include g.mq{
                margin-top: 0;
                margin-right: 0;
                margin-left: 4%;
              }
              .o-btn{
                font-size: 14px;
                padding-left: 25px;
                @include g.mq{
                  margin-left: 0;
                  margin-right: auto;
                }
              }
            }
            .o-btn{
              @include g.mq{
                margin-left: auto;
                margin-right: 0;
              }
            }
          }
        }
        > li{
          background: #F2F8F2;
          margin-bottom: 30px;
          border-radius: 12px;
          dl{
            @include g.mq{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
            }
            dd{
              img{
                border-radius: 12px;
                @include g.mq{
                  border-radius: 0;
                }
              }
              @include g.mq{
                padding-top: 60px;;
                padding-bottom: 25px;
                width: 37%;
              }
            }
            dt{
              padding: 50px 20px 20px;
              font-size: 12px;
              line-height: 1.7;
              position: relative;
              @include g.mq{
                font-size: 14px;
                width: 59%;
                padding-top: 10px;
                padding-left: 30px;
                padding-right: 30px;
              }
              .o-btn{
                margin-top: 30px;
              }
              h3{
                color: g.$c_grn;
                font-weight: bold;
                margin-bottom: 10px;
                line-height: 1.4;
                font-size: 18px;
                @include g.mq{
                  padding-left: 115px;
                  font-size: 24px;
                  margin-bottom: 15px;
                }
              }
              span{
                position: absolute;
                top: 0;
                left: 0;
                background: #4CB951;
                height: 40px;
                width: 120px;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-align-items: center;
                align-items: center;
                border-radius: 12px 30px 30px 0;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                padding-top: 3px;
              }
            }
          }
        }
      }
    }
  }
  @include g.e(work){
    background: #F8F5D4;
    padding-bottom: 30px;
    @include g.mq{
      padding-bottom: 50px;
    }
    ul{
      margin-top: 20px;
      @include g.mq{
        margin-top: 40px;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      li{
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
        margin: 0 auto 20px;
        max-width: 324px;
        @include g.mq{
          width: 32.3%;
          margin-left: 0;
          margin-right: 1.5%;
          margin-bottom: 40px;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        dl{
          text-align: center;
          dd{
            padding: 16px 16px 0;
          }
          dt{
            padding: 10px 20px 20px;
            font-size: 12px;
            @include g.mq{
              font-size: 14px;
              padding-left: 25px;
              padding-right: 25px;
            }
            p{
              text-align: left;
            }
            h3{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              max-width: 80%;
              margin: -24px auto 20px;
              line-height: 1;
              height: 30px;
              font-size: 16px;
              position: relative;
              border-radius: 20px;
              background: #4CB951;
              color: #fff;
              font-weight: bold;
              padding-top: 5px;
              width: 100%;
              @include g.mq{
                max-width: 90%;
                height: 40px;
                font-size: 18px;
                margin-top: -32px;
              }
              &:after{
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-top: 6px solid #4CB951;
                border-bottom: 0;
                position: absolute;
                bottom: -6px;
                left: 50%;
                -webkit-transform: translateX(-50%); /* Safari用 */
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }
  }
  @include g.e(vison){
    background: #DAF7DA;
    padding-bottom: 20px;
    @include g.mq{
      padding-bottom: 60px;
    }
    ul{
      text-align: center;
      li{
        margin-bottom: 25px;
        background: #fff;
        border-radius: 12px;
        padding: 55px 20px 20px;
        position: relative;
        @include g.mq{
          padding: 10px 30px 20px;
        }
        dl{
          color: g.$c_grn;
          dd{
            font-size: 14px;
            @include g.mq{
              font-size: 20px;
            }
          }
          dt{
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            @include g.mq{
              font-size: 24px;
            }
            &:before{
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 16px;
              height: 16px;
              margin-right: 8px;
              background: url(../images/common/icon_check.png) center no-repeat;
              background-size: 16px;
              @include g.mq{
                width: 22px;
                height: 22px;
                background-size: 22px;
              }
            }
          }
        }
        span{
          position: absolute;
          top: 0;
          left: 0;
          background: #4CB951;
          height: 40px;
          width: 118px;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          border-radius: 12px 30px 30px 0;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          padding-top: 5px;
        }
      }
    }
  }
  @include g.e(performance){
    background: #F8F5D4;
    padding-bottom: 40px;
    @include g.mq{
      padding-bottom: 60px;
    }
    @include g.e(content){
      background: #fff;
      border-radius: 12px;
      padding: 15px 20px 20px;
      @include g.mq_m{
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 40px;
      }
      ul{
        margin-bottom: 20px;
        @include g.mq{
          margin-bottom: 40px;
        }
        li{
          padding-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #D5FCD5;
          dl{
            @include g.mq{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
            }
            dd{
              font-weight: bold;
              @include g.mq{
                font-size: 15px;
                width : -webkit-calc(100% - 140px) ;
                width : calc(100% - 140px) ;
                padding-left: 25px;
              }
              a{
                color: g.$c_grn;
              }
            }
            dt{
              width: 140px;
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              min-height: 30px;
              font-size: 12px;
              font-weight: bold;
              color: #fff;
              text-align: center;
              background: #4CB951;
              border-radius: 20px;
              padding-top: 3px;
              margin-bottom: 5px;
              @include g.mq{
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  @include g.e(brain){
    background: #F2F8F2;
    padding-bottom: 40px;
    @include g.mq{
      padding-bottom: 60px;
    }
    .o-btn{
      margin-top: 20px;
      @include g.mq{
        margin-top: 40px;
      }
    }
  }
  @include g.e(member){
    background: #DAF7DA;
    padding-bottom: 50px;
    @include g.mq{
      padding-bottom: 70px;
    }
    @include g.m(partner){
      padding-bottom: 20px;
    }
    @include g.m(guide){
      background: #F2F8F2;
    }
    .is-text{
      margin-bottom: 20px;
      @include g.mq{
        margin-bottom: 40px;
      }
    }
    @include g.e(content){
      margin-top: 10px;
      @include g.mq{
        margin-top: 30px;
      }
      .is-list{
        margin-bottom: 20px;
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          margin-bottom: 30px;
        }
        li{
          background: #fff;
          box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
          padding: 20px;
          &:first-child{
            border-radius: 12px 12px 0 0;
          }
          &:last-child{
            border-radius: 0 0 12px 12px;
          }
          @include g.mq{
            padding: 30px;
            width: 48%;
            margin-right: 4%;
            border-radius: 0;
            &:nth-child(2n){
              margin-right: 0;
            }
            &:nth-child(2){
              border-radius: 12px 12px 0 0;
            }
            &:nth-last-child(2){
              border-radius: 0 0 12px 12px;
            }
          }
          > dl{
            > dd{
              line-height: 1.7;
              margin-bottom: 15px;
            }
            > dt{
              dl{
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -webkit-justify-content: center; /* Safari用 */
                justify-content: center;
                -webkit-align-items: center; /* Safari用 */
                align-items: center;
                @include g.mq{
                  max-width: 90%;
                  margin: 0 auto;
                }
                dt{
                  color: g.$c_grn;
                  font-size: 12px;
                  line-height: 1.7;
                  width : -webkit-calc(100% - 100px) ;
                  width : calc(100% - 100px) ;
                  @include g.mq{
                    font-size: 14px;
                  }
                  h4{
                    font-size: 16px;
                    @include g.mq{
                      font-size: 18px;
                    }
                  }
                }
                dd{
                  min-width: 82px;
                  width: 82px;
                  margin-right: 18px;
                }
              }
            }
          }
          h3{
            text-align: center;
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            background: #F2F8F2;
            border-radius: 25px;
            height: 40px;
            color: #4CB951;
            font-size: 14px;
            font-weight: bold;
            padding-top: 5px;
            max-width: 94%;
            margin: 0 auto 20px;
            position: relative;
            @include g.mq{
              max-width: 380px;
              font-size: 17px;
              height: 50px;
            }
            &:after{
              content: "";
              display: block;
              position: absolute;
              left: 20px;
              top: 50%;
              -webkit-transform: translateY(-50%); /* Safari用 */
              transform: translateY(-50%);
              background: url(../images/common/icon_mic.png) center no-repeat;
              background-size: 100%;
              width: 18px;
              height: 24px;
            }
          }
        }
      }
      .slick-track {
        display: flex;
      }
      .slick-slide {
        height: auto !important;
      }
      .slick-prev{
        left: 0px;
        width: 42px;
        height: 42px;
        background: url(../images/common/arw_prev.png) center no-repeat g.$c_ore;
        background-size: 12px;
        border-radius: 50%;
        z-index: 1;
        @include g.mq{
          left: -25px;
          background-size: 20px;
          width: 62px;
          height: 62px;
        }
        &:before{
          display: none;
        }
      }
      .slick-next{
        right: 0px;
        width: 42px;
        height: 42px;
        background: url(../images/common/arw_next.png) center no-repeat g.$c_ore;
        background-size: 12px;
        border-radius: 50%;
        z-index: 1;
        @include g.mq{
          right: -20px;
          background-size: 20px;
          width: 62px;
          height: 62px;
        }
        &:before{
          display: none;
        }
      }
      .slick-list{
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .is-slide{
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
        margin-left: 5px;
        margin-right: 5px;
        padding: 20px;
        > dl{
          > dd{
            line-height: 1.7;
            margin-bottom: 25px;
            @include g.mq{
              min-height: 218px;
            }
          }
          > dt{
            background: #DAF7DA;
            padding: 15px 20px;
            position: relative;
            border-radius: 0 0 12px 12px;
            &:after{
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 50%;
              -webkit-transform: translateX(-50%); /* Safari用 */
              transform: translateX(-50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-right: 14px solid transparent;
              border-left: 14px solid transparent;
              border-top: 15px solid #fff;
              border-bottom: 0;
            }
            dl{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              @include g.mq{
                max-width: 90%;
                margin: 0 auto;
              }
              dt{
                color: g.$c_grn;
                font-size: 12px;
                line-height: 1.7;
                width : -webkit-calc(100% - 100px) ;
                width : calc(100% - 100px) ;
                @include g.mq{
                  font-size: 14px;
                }
                h4{
                  font-size: 16px;
                  @include g.mq{
                    font-size: 18px;
                  }
                }
              }
              dd{
                min-width: 82px;
                width: 82px;
                margin-right: 18px;
              }
            }
          }
        }
        h3{
          text-align: center;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          background: #F2F8F2;
          border-radius: 25px;
          height: 40px;
          color: #4CB951;
          font-size: 14px;
          font-weight: bold;
          padding-top: 5px;
          max-width: 94%;
          margin: 0 auto 20px;
          position: relative;
          @include g.mq{
            max-width: 380px;
            font-size: 17px;
            height: 50px;
          }
          &:after{
            content: "";
            display: block;
            position: absolute;
            left: 20px;
            top: 50%;
            -webkit-transform: translateY(-50%); /* Safari用 */
            transform: translateY(-50%);
            background: url(../images/common/icon_mic.png) center no-repeat;
            background-size: 100%;
            width: 18px;
            height: 24px;
          }
        }
      }
    }
  }
  @include g.e(content){
    background: #F2F8F2;
    padding-bottom: 30px;
    @include g.mq{
      padding-bottom: 60px;
    }
    @include g.e(box){
      background: #fff;
      border-radius: 12px 12px 0 0;
      padding: 20px;
      @include g.mq{
        padding: 40px;
      }
      @include g.m(under){
        background: #DAF7DA;
        border-radius: 0 0 12px 12px;
        > dl{
          @include g.mq{
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            max-width: 710px;
            margin: 0 auto 20px;
          }
          > dd{
            display: none;
            @include g.mq{
              min-height: inherit;
              min-width: inherit;
              width: 280px;
              margin-left: 0;
            }
          }
          > dt{
            @include g.mq{
            }
            dl{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-bottom: 20px;
              @include g.mq{
                margin-bottom: 0;
              }
              dd{
                width: 86px;
              }
              dt{
                width : -webkit-calc(100% - 86px) ;
                width : calc(100% - 86px) ;
                padding-left: 15px;
                color: g.$c_grn;
                line-height: 1.5;
                @include g.mq{
                  line-height: 1.3;
                }
                span{
                  display: block;
                  font-size: 16px;
                  @include g.mq{
                    display: inline;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
      h3{
        font-size: 16px;
        font-weight: bold;
        color: g.$c_grn;
        margin-bottom: 10px;
        @include g.mq{
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
      p{
        margin-bottom: 20px;
      }
      > dl{
        margin-bottom: 20px;
        @include g.mq{
          margin-bottom: 0;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          @include g.m(last){
            > dd{
              min-width: 370px;
            }
          }
          &:last-child{
            > dd{
              min-width: 370px;
            }
          }
        }
        @include g.m(list){
          margin-bottom: 20px;
          > dt{
            @include g.mq{
              width: 45%;
            }
          }
          > dd{
            @include g.mq{
              width: 55%;
            }
          }
        }
        > dt{
          ul{
            margin-bottom: 20px;
            @include g.mq{
              margin-bottom: 0;
            }
            li{
              font-size: 16px;
              color: g.$c_grn;
              padding-top: 5px;
              padding-bottom: 5px;
              @include g.mq{
                font-size: 20px;
              }
              &:before{
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                background: url(../images/common/icon_check.png) center no-repeat;
                background-size: 16px;
              }
            }
          }
        }
        > dd{
          text-align: center;
          @include g.mq{
            min-height: 190px;
            min-width: 290px;
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            margin-left: 20px;
          }
        }
      }
    }
  }
  @include g.e(read){
    background: url(../images/itswellness/read_bg.png) center bottom no-repeat #fff;
    background-size: 100%;
    position: relative;
    height: 130px;
    @include g.mq{
      height: 250px;
    }
    h2{
      font-weight: bold;
      font-size: 16px;
      color: g.$c_grn;
      padding-top: 20px;
      @include g.mq{
        font-size: 24px;
        padding-top: 40px;
      }
    }

  }
}
