@use "../global/" as g;

.p-policy{
  padding-top: 30px;
  padding-bottom: 10px;
  @include g.mq{
    padding-top: 40px;
  }
  @include g.m(terms){
    .p-policy__content__data dl{
      &:last-child{
        margin-bottom: 0;
      }
      dt{
        p{
          font-size: 12px;
          margin-bottom: 20px;
          @include g.mq{
            font-size: 14px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
        h3{
          font-size: 14px;
          line-height: 1.7;
          @include g.mq{
            font-size: 15px;
          }
        }
      }
    }
  }
  @include g.e(content){
    padding: 20px;
    border-radius: 12px;
    background: #F2F8F2;
    margin-bottom: 30px;
    @include g.mq{
      padding: 40px;
      margin-bottom: 40px;
    }
    @include g.m(top){
      background: #F8F5D4;
      @include g.mq{
        padding-top: 25px;
      }
    }
    @include g.e(cookie){
      h2{
        font-size: 18px;
        color: g.$c_grn;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 30px;
        @include g.mq{
          margin-top: 40px;
          font-size: 24px;
        }
      }
      p{
        margin-bottom: 20px;
      }
      table{
        width: 100%;
        table-layout: fixed;
        margin-bottom: 25px;
        td{
          padding: 15px 10px;
          border-bottom: 1px solid #D5FCD5;
          &:first-child{
            font-weight: bold;
          }
          &:nth-child(3){
            text-align: center;
          }
        }
        th{
          color: g.$c_grn;
          font-weight: bold;
          text-align: center;
          padding: 15px 10px;
          border-top: 1px solid g.$c_grn;
          border-bottom: 1px solid g.$c_grn;
          &:first-child{
            text-align: left;
          }
          &:nth-child(2){
            width: 64%;
          }
        }
      }
      .is-list{
        ul li{
          color: g.$c_grn;
          position: relative;
          padding-left: 18px;
          padding-top: 4px;
          font-weight: bold;
          &:before{
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            background: g.$c_grn;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%); /* Safari用 */
            transform: translateY(-50%);
          }
        }
      }
    }
    @include g.e(box){
      .is-link{
        color: g.$c_grn;
        a{
          color: g.$c_grn;
        }
      }
      dl{
        color: g.$c_grn;
        margin-top: 20px;
        p{
          margin-top: 10px;
        }
        dt{
          margin-top: 10px;
        }
      }
      h3{
        font-size: 18px;
        font-weight: bold;
        color: g.$c_grn;
        margin-top: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #D5FCD5;
        @include g.mq{
          padding-bottom: 5px;
          font-size: 24px;
          margin-top: 30px;
          margin-bottom: 20px;
        }
        &:first-child{
          margin-top: 0;
        }
      }
    }
    @include g.e(data){
      h2{
        font-size: 18px;
        text-align: center;
        color: g.$c_grn;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 20px;
        @include g.mq{
          margin-top: 40px;
          font-size: 24px;
          margin-bottom: 30px;
        }
      }
      dl{
        margin-bottom: 20px;
        @include g.mq{
          margin-bottom: 30px;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        dd{
          font-weight: bold;
          color: g.$c_grn;
          @include g.mq{
            width: 280px;
          }
        }
        dt{
          color: #969696;
          line-height: 1.6;
          @include g.mq{
            width : -webkit-calc(100% - 280px) ;
            width : calc(100% - 280px) ;
          }
          h3{
            color: g.$c_grn;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.3;
            @include g.mq{
              font-size: 20px;
            }
          }
        }
      }
    }
    @include g.e(text){
      color: g.$c_grn;
      @include g.mq{
        font-size: 15px;
        line-height: 1.8;
      }
      p{
        margin-bottom: 20px;
        @include g.mq{
          margin-bottom: 40px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}
