@use "../global/" as g;

.p-contact{
  padding-top: 30px;
  padding-bottom: 30px;
  @include g.mq{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @include g.m(en){
    .p-contact__content dl{
      dd{
        @include g.mq{
          width: -webkit-calc(100% - 200px);
          width: calc(100% - 200px);
        }
      }
      dt{
        margin-right: 80px;
        span{
          right: -70px;
          @include g.mq{
            font-size: 14px;
          }
        }
      }
    }
  }
  :placeholder-shown {
    color: #969696;
  }
  ::-webkit-input-placeholder {
    color: #969696;
  }
  :-moz-placeholder {
    color: #969696; opacity: 1;
  }
  ::-moz-placeholder {
    color: #969696; opacity: 1;
  }
  :-ms-input-placeholder {
    color: #969696;
  }
  input:focus, textarea:focus, select:focus{
    outline-offset: none;
  }
  :focus {
    outline: none;
  }
  label{
    position: relative;
  }
  button, input, select, textarea {
    font-family : inherit;
  }
  input[type="text"] ,input[type="email"] , select{
    font-size: 16px;
    box-shadow: 0 1px 1px rgba(000, 000, 000, 0.16) inside;
    background: #fff;
    padding: 6px 12px;
    width: 100%;
    border-radius: 8px;
    min-height: 48px;
  }
  select{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 55px;
    background: url(../images/common/select_arw.png) right 25px center no-repeat #fff;
    background-size: 28px;
    color: g.$c_grn;
  }
  textarea{
    width: 100%;
    resize: none;
    height: 160px;
    box-shadow: 0 1px 1px rgba(000, 000, 000, 0.16) inside;
    font-size: 16px;
    padding: 10px 12px;
    border-radius: 8px;
    line-height: 1.5;
  }
  input[type="checkbox"] , input[type="radio"]{
    display: none;
  }
  input[type=radio] + span {
    padding: 0 0 0 30px;
    position: relative;
    min-height: 20px;
    margin-right: 20px;
    font-weight: bold;
    color: g.$c_grn;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      left: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 1px 1px rgba(000, 000, 000, 0.16) inside;
      display: block;
    }
  }
  input[type=radio]:checked + span:after {
    content: "";
    display: block;
    position: absolute;
    top: 9px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: g.$c_grn;
  }
  input[type=checkbox] + span {
    position: relative;
    min-height: 20px;
    margin-right: 20px;
    font-weight: bold;
    color: g.$c_grn;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      left: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      box-shadow: 0 1px 1px rgba(000, 000, 000, 0.16) inside;
      display: block;
    }
  }
  input[type=checkbox]:checked + span:after {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 5px;
    width: 10px;
    height: 8px;
    border-left: 2px solid g.$c_grn;
    border-bottom: 2px solid g.$c_grn;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  @include g.e(content){
    padding: 20px 20px 30px;
    border-radius: 12px;
    background: #F2F8F2;
    @include g.mq{
      padding: 40px 40px 50px;
    }
    .error{
      margin-top: 5px;
    }
    .is-check{
      text-align: center;
      color: g.$c_grn;
      label{
        display: inline-block;
        vertical-align: middle;
      }
      a{
        color: #4CB951;
        text-decoration: underline;
      }
      .error{
        display: inline;
      }
    }
    .o-btn{
      margin-top: 20px;
    }
    p{
      color: g.$c_grn;
      font-size: 13px;
      max-width: 620px;
      margin: 20px auto 0;
      @include g.mq{
        font-size: 15px;
      }
    }
    dl{
      max-width: 720px;
      margin: 0 auto 20px;
      @include g.mq{
        margin-bottom: 30px;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      @include g.m(last){
        @include g.mq{
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
      }
      dt{
        max-width: 120px;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        height: 28px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        background: #4CB951;
        text-align: center;
        margin-bottom: 15px;
        margin-right: 60px;
        border-radius: 20px;
        width: 100%;
        padding-top: 3px;
        position: relative;
        @include g.mq{
          margin-bottom: 0;
        }
        span{
          position: absolute;
          right: -45px;
          top: 50%;
          -webkit-transform: translateY(-50%); /* Safari用 */
          transform: translateY(-50%);
          color: g.$c_ore;
          font-size: 14px;
          font-weight: bold;
          @include g.mq{
            font-size: 16px;
          }
        }
      }
      dd{
        @include g.mq{
          width : -webkit-calc(100% - 180px) ;
          width : calc(100% - 180px) ;
        }
        @include g.m(radio){
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
          }
        }
        @include g.m(check){
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
        }
      }
    }
  }
}
