@use "../global/" as g;

.o-pagination{
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  li{
    padding: 8px 4px;
    background: #F5F5F5;
    &.is-first , &.is-last{
      padding: 0;
      background: transparent;
    }
    &.is-first a,&.is-last a{
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: g.$c_ore;
      background-size: 19px;
      background-position: center;
      background-repeat: no-repeat;
      @include g.mq{
        width: 62px;
        height: 62px;
      }
    }
    &.is-first{
      margin-right: 10px;
      a{
        background-image: url(../images/common/arw_prev.png);
      }
    }
    &.is-last{
      margin-left: 10px;
      a{
        background-image: url(../images/common/arw_next.png);
      }
    }

    &.is-current a {
      color: #fff;
      background: g.$c_ore;
    }
    a{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: g.$c_ore;
      border-radius: 6px;
      @include g.mq{
        width: 56px;
        height: 56px;
        line-height: 56px;
      }
      &:hover,&:active{
        color: #fff;
        background-color: g.$c_ore;
      }
    }
  }
}
