@use "../global/" as g;

.o-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  text-align: center;
  max-width: 280px;
  cursor: pointer;
  margin: 0 auto;
  background-color: g.$c_ore;
  border-radius: 30px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  border: none;
  box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
  padding-top: 5px;
  @include g.mq{
    height: 48px;
  }
  &:before{
    content: "";
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background: url(../images/common/arw.png) center no-repeat #fff;
    background-size: 7px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari用 */
    transform: translateY(-50%);
  }
  &:hover , &:active{
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: 0.6s ease-in-out;
    -moz-transition: 0.6s ease-in-out;
    -o-transition: 0.6s ease-in-out;
    transition: 0.6s ease-in-out;
  }
  @include g.m(grn){
    background-color: #179451;
    &:before{
      background-image: url(../images/common/arw_gn.png);
    }
  }
  @include g.m(event_btn){
    border-radius: 12px;
    overflow: hidden;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background: url(../images/top/event_bg.png) left center no-repeat g.$c_grn;
    background-size: 100%;
    height: 150px;
    max-width: 100%;
    padding-left: 22%;
    text-align: left;
    @include g.mq{
      background-image: url(../images/top/event_bg_pc.png);
      background-size: 1000px;
      height: 192px;
      padding-left: 100px;
    }
    &:before{
      display: none;
    }
    dl{
      color: #fff;
      dt{
        font-size: 24px;
        font-weight: bold;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        margin-bottom: 10px;
        line-height: 1;
        @include g.mq{
          font-size: 45px;
          margin-bottom: 15px;
        }
        span{
          font-size: 14px;
          padding-left: 10px;
          @include g.mq{
            font-size: 18px;
          }
        }
      }
      dd{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        border-radius: 20px;
        background: #F2F8F2;
        font-size: 12px;
        color: #4CB951;
        padding: 6px 12px;
        text-align: center;
        font-weight: bold;
        @include g.mq{
          padding: 8px 20px;
          font-size: 16px;
        }
      }
    }
  }
}
