@use "../global/" as g;

.p-tour{
  @include g.m(single){
    .o-page_link{
      ul li p{
        max-width: 230px;
        @include g.mq{
          max-width: 280px;
        }
      }
      h2{
        color: g.$c_grn;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        @include g.mq{
          font-size: 28px;
        }
      }
    }
    .o-title{
      transform: translateY(-15px);
      margin-bottom: 0;
      @include g.mq{
        transform: translateY(-24px);
      }
      h2{
        @include g.mq{
          margin-top: 25px;
        }
      }
    }
  }
  @include g.m(top){
    .p-tour__archive{
      padding-top: 0;
      .o-title{
        transform: translateY(-15px);
        margin-bottom: 0;
        @include g.mq{
          transform: translateY(-24px);
        }
        h2{
          @include g.mq{
            margin-top: 25px;
          }
        }
      }
    }
  }
  @include g.e(single_other){
    background: #F2F8F2;
    padding-bottom: 20px;
    @include g.mq{
      padding-bottom: 30px;
    }
    @include g.e(btn){
      @include g.mq{
        text-align: center;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
      }
      .o-btn{
        margin-bottom: 20px;
        @include g.mq{
          margin-left: 0;
          margin-right: 0;
          &:last-child{
            margin-left: 20px;
          }
        }
      }
    }
    @include g.e(list){
      > ul{
        margin-bottom: 30px;
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        > li{
          margin-bottom: 20px;
          background: #fff;
          border-radius: 12px;
          position: relative;
          padding: 50px 20px 20px;
          @include g.mq{
            width: 48%;
            margin-right: 4%;
            padding-left: 30px;
            padding-right: 30px;
            &:nth-child(2n){
              margin-right: 0;
            }
          }
          .is-content{
            p{
              margin-bottom: 10px;
            }
            strong{
              color: g.$c_grn;
              font-weight: bold;
              @include g.mq{
                font-size: 18px;
              }
            }
          }
          .is-title{
            color: g.$c_grn;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            @include g.mq{
              font-size: 24px;
            }
          }
          .is-check{
            position: absolute;
            top: 0;
            left: 0;
            background: #4CB951;
            height: 40px;
            width: 120px;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 12px 30px 30px 0;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            padding-top: 3px;
          }
        }
      }
    }
  }
  @include g.e(single_plan){
    background: #F8F5D4;
    padding-bottom: 50px;
    @include g.mq{
      padding-bottom: 70px;
    }
    @include g.e(content){
      > dl{
        margin-bottom: 30px;
        border-radius: 12px;
        @include g.mq{
          margin-bottom: 40px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        > dt{
          background: #fff;
          padding: 20px 20px 10px;
          @include g.mq{
            padding: 40px 50px 10px;
          }
          .is-content{
            color: g.$c_grn;
            font-size: 12px;
            line-height: 1.7;
            @include g.mq{
              font-size: 14px;
            }
            > ul{
              margin-bottom: 20px;
              @include g.mq{
                margin-bottom: 30px;
              }
            }
          }
          > dl{
            background: #F2F8F2;
            border-radius: 12px;
            overflow: hidden;
            margin-bottom: 20px;
            @include g.mq{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-bottom: 30px;
            }
            > dd{
              img{
                border-radius: 12px;
                @include g.mq{
                  border-radius: 0;
                  width: 100%;
                  min-height: 180px;
                  object-fit: cover;
                }
              }
              @include g.mq{
                width: 41%;
              }
            }
            > dt{
              text-align: center;
              padding: 20px;
              position: relative;
              @include g.mq{
                padding-top: 35px;
                padding-bottom: 35px;
                width: 59%;
              }
              p{
                line-height: 1;
                font-size: 30px;
                color: g.$c_grn;
                font-weight: bold;
                @include g.mq{
                  font-size: 40px;
                }
              }
              h3{
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -webkit-justify-content: center; /* Safari用 */
                justify-content: center;
                -webkit-align-items: center; /* Safari用 */
                align-items: center;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                max-width: 100%;
                margin: 0 auto 15px;
                line-height: 1;
                height: 35px;
                font-size: 14px;
                position: relative;
                border-radius: 20px;
                background: #4CB951;
                color: #fff;
                font-weight: bold;
                padding-top: 5px;
                @include g.mq{
                  max-width: 420px;
                  height: 50px;
                  font-size: 20px;
                  margin-bottom: 25px;
                }
              }
            }
          }
        }
        > dd{
          background: #DAF7DA;
          padding: 20px;
          @include g.mq{
            padding-top: 40px;
            padding-bottom: 40px;
          }
        }
      }
    }
  }
  @include g.e(single_recommend){
    background: #DAF7DA;
    padding-bottom: 50px;
    @include g.mq{
      padding-bottom: 70px;
    }
    @include g.e(list){
      > dl{
        margin-bottom: 25px;
        background: #F2F8F2;
        border-radius: 12px;
        @include g.mq{
          margin-bottom: 35px;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        &:last-child{
          margin-bottom: 0;
        }
        > dd{
          text-align: center;
          padding: 0 20px 20px;
          @include g.mq{
            padding: 60px 40px 25px 0;
            width: 40%;
          }
          img{
            border-radius: 12px;
            @include g.mq{
              border-radius: 0;
            }
          }
        }
        > dt{
          padding: 50px 20px 20px;
          position: relative;
          @include g.mq{
            width: 60%;
            padding-top: 10px;
            padding-left: 40px;
            padding-right: 40px;
          }
          > h3{
            color: g.$c_grn;
            font-weight: bold;
            margin-bottom: 10px;
            line-height: 1.4;
            font-size: 18px;
            @include g.mq{
              padding-left: 115px;
              font-size: 24px;
              margin-bottom: 15px;
            }
          }
          .is-num{
            position: absolute;
            top: 0;
            left: 0;
            background: #4CB951;
            height: 40px;
            width: 120px;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 12px 30px 30px 0;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            padding-top: 3px;
          }
        }
      }
    }
  }
  @include g.e(single_table){
    background: #F2F8F2;
    padding-bottom: 50px;
    @include g.mq{
      padding-bottom: 60px;
    }
    @include g.e(tour){
      max-width: 900px;
      margin: 0 auto;
      > dl{
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 25px;
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        @include g.m(time){
          > dd{
            font-size: 12px;
            @include g.mq{
              font-size: 14px;
            }
            h3{
              color: #969696;
              margin-bottom: 10px;
              strong{
                font-weight: bold;
                color: g.$c_grn;
              }
            }
          }
        }
        @include g.m(day){
          > dd{
            color: g.$c_grn;
            font-weight: bold;
          }
        }
        > dt{
          border-radius: 12px 12px 0 0;
          background: #4CB951;
          padding: 20px;
          position: relative;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          @include g.mq{
            width: 260px;
            padding-left: 25px;
            padding-right: 25px;
            border-radius: 12px 0 0 12px;
            &:after{
              content: "";
              display: block;
              position: absolute;
              right: -10px;
              top: 50%;
              -webkit-transform: translateY(-50%); /* Safari用 */
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #4CB951;
              border-right: 0;
            }
          }
          p{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            width: 100%;
            max-width: 190px;
            margin: auto;
            line-height: 1;
            height: 30px;
            font-size: 14px;
            position: relative;
            border-radius: 20px;
            background: #F2F8F2;
            color: #4CB951;
            font-weight: bold;
            padding-top: 5px;
            @include g.mq{
              max-width: 215px;
              height: 35px;
            }
          }
        }
        > dd{
          border-radius: 0 0 12px 12px;
          padding: 20px;
          background: #fff;
          @include g.mq{
            width : -webkit-calc(100% - 260px) ;
            width : calc(100% - 260px) ;
          }
          @include g.mq_m{
            padding: 40px;
          }
          > ul > li{
            position: relative;
            margin-bottom: 15px;
            background: #F2F8F2;
            padding: 20px;
            border-radius: 12px;
            &:last-child{
              margin-bottom: 0;
              &:after{
                display: none;
              }
            }
            &:after{
              content: "";
              display: block;
              width: 4px;
              height: 15px;
              background: #4CB951;
              position: absolute;
              left: 90px;
              bottom: -15px;
            }
            > ul{
              margin-top: 15px;
              @include g.mq{
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
              }
              > li{
                margin-bottom: 15px;
                border-radius: 4px;
                background: #fff;
                padding: 13px 15px;
                color: g.$c_grn;
                font-size: 12px;
                font-weight: bold;
                &:last-child{
                  margin-bottom: 0;
                }
                &:before{
                  content: "";
                  display: inline-block;
                  margin-right: 8px;
                  vertical-align: middle;
                  width: 16px;
                  height: 16px;
                  background: url(../images/common/icon_check.png) no-repeat;
                  background-size: 100%;
                }
                @include g.mq{
                  font-size: 14px;
                  width: 48%;
                  margin-right: 4%;
                  &:nth-child(2n){
                    margin-right: 0;
                  }
                  &:nth-last-child(2){
                    margin-bottom: 0;
                  }
                }
              }
            }
            > dl{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              > dt{
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -webkit-justify-content: center; /* Safari用 */
                justify-content: center;
                -webkit-align-items: center; /* Safari用 */
                align-items: center;
                width: 100px;
                min-width: 100px;
                line-height: 1;
                height: 30px;
                border-radius: 20px;
                background: #4CB951;
                color: #fff;
                font-weight: bold;
                padding-top: 5px;
                @include g.mq{
                  height: 35px;
                  width: 130px;
                  min-width: 130px;
                }
              }
              > dd{
                margin-left: 15px;
                color: g.$c_grn;
                font-weight: bold;
              }
            }
          }
        }
      }
      .o-btn{
        margin-top: 30px;
      }
    }
  }
  @include g.e(single_title){
    background: url(../images/tour/tour_bg.png) center top no-repeat #daf7da;
    background-size: 100%;
    padding-top: 40px;
    padding-bottom: 50px;
    @include g.mq{
      padding-top: 80px;
      padding-bottom: 70px;
      background-position: center top -100px;
    }
    .o-title{
      transform: translateY(0);
      margin-bottom: 15px;
      @include g.mq{
        transform: translateY(0);
        margin-bottom: 30px;
      }
      h2{
        @include g.mq{
          margin-top: 0;
        }
      }
    }
    @include g.e(check){
      > dl{
        background: #fff;
        border-radius: 12px;
        overflow: hidden;
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        > dd{
          img{
            border-radius: 12px;
            @include g.mq{
              border-radius: 0;
              width: 100%;
              min-height: 210px;
              object-fit: cover;
            }
          }
          @include g.mq{
            width: 41%;
          }
        }
        > dt{
          padding: 45px 20px 20px;
          font-size: 12px;
          line-height: 1.7;
          position: relative;
          @include g.mq{
            font-size: 14px;
            width: 59%;
            padding-left: 30px;
            padding-right: 30px;
          }
          .is-check{
            position: absolute;
            top: 0;
            left: 0;
            background: #4CB951;
            height: 28px;
            width: 120px;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 12px 30px 30px 0;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            padding-top: 3px;
          }
        }
      }
    }
  }
  @include g.e(link){
    background: #F2F8F2;
    .o-title{
      transform: translateY(-15px);
      margin-bottom: 0;
      @include g.mq{
        transform: translateY(-24px);
      }
      h2{
        @include g.mq{
          margin-top: 25px;
        }
      }
    }
    .o-page_link{
      padding-top: 0;
      padding-bottom: 50px;
      @include g.mq{
        padding-bottom: 80px;
      }
      ul li p{
        max-width: 230px;
        @include g.mq{
          max-width: 280px;
        }
      }
    }
  }
  @include g.e(pickup){
    background: #F8F5D4;
    padding-top: 30px;
    padding-bottom: 20px;
    @include g.mq{
      padding-top: 40px;
      padding-bottom: 30px;
    }
    @include g.e(content){
      > ul > li{
        max-width: 900px;
        margin: 0 auto 30px;
        box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
        border-radius: 12px;
        background: #fff;
        > dl{
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          > dt{
            padding: 20px;
            @include g.mq{
              width: 66.7%;
            }
            h3{
              font-weight: bold;
              font-size: 16px;
              color: g.$c_grn;
              line-height: 1.2;
              margin-bottom: 15px;
              @include g.mq{
                font-size: 20px;
                margin-bottom: 20px;
              }
            }
            .is-link{
              padding-top: 15px;
              @include g.mq{
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -webkit-align-items: center; /* Safari用 */
                align-items: center;
                padding-top: 20px;
              }
              a{
                color: g.$c_ore;
                font-weight: bold;
                display: block;
                &:nth-child(2){
                  margin-top: 10px;
                  @include g.mq{
                    margin-top: 0;
                    margin-left: 30px;
                  }
                }
                &:before{
                  content: "";
                  width: 28px;
                  height: 28px;
                  display: inline-block;
                  vertical-align: middle;
                  background: url(../images/common/arw_wh.png) center no-repeat g.$c_ore;
                  background-size: 7px;
                  border-radius: 50%;
                  margin-right: 10px;
                }
              }
            }
            .is-data{
              padding: 15px 20px;
              border-radius: 12px;
              background: #F2F8F2;
              @include g.mq{
                padding: 20px 15px;
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -webkit-align-items: center; /* Safari用 */
                align-items: center;
              }
              dl{
                display: -webkit-flex; /* Safari用 */
                display: flex;
                -webkit-align-items: center; /* Safari用 */
                align-items: center;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                &:last-child{
                  margin-top: 10px;
                  @include g.mq{
                    margin-top: 0;
                    margin-left: 20px;
                  }
                }
                dt{
                  background: #4CB951;
                  display: -webkit-flex; /* Safari用 */
                  display: flex;
                  -webkit-justify-content: center; /* Safari用 */
                  justify-content: center;
                  -webkit-align-items: center; /* Safari用 */
                  align-items: center;
                  width: 74px;
                  height: 24px;
                  color: #fff;
                  font-size: 13px;
                  border-radius: 16px;
                  font-weight: bold;
                  padding-top: 3px;
                }
                dd{
                  width : -webkit-calc(100% - 74px) ;
                  width : calc(100% - 74px) ;
                  padding-left: 8px;
                  color: #4CB951;
                  font-weight: bold;
                  font-size: 12px;
                  @include g.mq{
                    font-size: 14px;
                  }
                }
              }
            }
          }
          > dd{
            position: relative;
            overflow: hidden;
            border-radius: 12px 12px 0 0;
            text-align: center;
            @include g.mq{
              width: 33.3%;
              border-radius: 12px 0 0 12px;
            }
            img{
              height: 200px;
              object-fit: cover;
              @include g.mq{
                height: 240px;
              }
            }
            span{
              position: absolute;
              top: 0;
              left: 0;
              background: #4CB951;
              height: 40px;
              width: 190px;
              display: -webkit-flex;
              display: flex;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-align-items: center;
              align-items: center;
              border-radius: 12px 30px 30px 0;
              color: #fff;
              font-size: 16px;
              font-weight: bold;
              padding-top: 3px;
            }
          }
        }
      }
    }
  }
  @include g.e(archive){
    background: #DAF7DA;
    padding-top: 30px;
    padding-bottom: 40px;
    @include g.mq{
      padding-top: 40px;
      padding-bottom: 70px;
    }
    @include g.e(content){
      > ul{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        > li{
          margin-bottom: 30px;
          box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
          border-radius: 12px;
          background: #fff;
          @include g.mq{
            margin-bottom: 50px;
            width: 32%;
            margin-right: 2%;
            &:nth-child(3n){
              margin-right: 0;
            }
          }
          > dl{
            > dt{
              padding: 20px;
              h3{
                font-weight: bold;
                font-size: 16px;
                color: g.$c_grn;
                line-height: 1.2;
                margin-bottom: 15px;
                @include g.mq{
                  font-size: 20px;
                }
              }
              .is-link{
                padding-top: 15px;
                @include g.mq{
                  display: -webkit-flex; /* Safari用 */
                  display: flex;
                  -webkit-align-items: center; /* Safari用 */
                  align-items: center;
                }
                a{
                  color: g.$c_ore;
                  font-weight: bold;
                  &:before{
                    content: "";
                    width: 28px;
                    height: 28px;
                    display: inline-block;
                    vertical-align: middle;
                    background: url(../images/common/arw_wh.png) center no-repeat g.$c_ore;
                    background-size: 7px;
                    border-radius: 50%;
                    margin-right: 10px;
                  }
                }
              }
              .is-data{
                padding: 15px 20px;
                border-radius: 12px;
                background: #F2F8F2;
                dl{
                  display: -webkit-flex; /* Safari用 */
                  display: flex;
                  -webkit-align-items: center; /* Safari用 */
                  align-items: center;
                  -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
                  &:last-child{
                    margin-top: 10px;
                  }
                  dt{
                    background: #4CB951;
                    display: -webkit-flex; /* Safari用 */
                    display: flex;
                    -webkit-justify-content: center; /* Safari用 */
                    justify-content: center;
                    -webkit-align-items: center; /* Safari用 */
                    align-items: center;
                    width: 74px;
                    height: 24px;
                    color: #fff;
                    font-size: 13px;
                    border-radius: 16px;
                    font-weight: bold;
                    padding-top: 3px;
                  }
                  dd{
                    width : -webkit-calc(100% - 74px) ;
                    width : calc(100% - 74px) ;
                    padding-left: 8px;
                    color: #4CB951;
                    font-weight: bold;
                    font-size: 12px;
                    @include g.mq{
                      font-size: 14px;
                    }
                  }
                }
              }
            }
            > dd{
              position: relative;
              overflow: hidden;
              border-radius: 12px 12px 0 0;
              text-align: center;
              img{
                height: 200px;
                object-fit: cover;
              }
              span{
                position: absolute;
                top: 0;
                left: 0;
                background: #4CB951;
                height: 40px;
                width: 190px;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-align-items: center;
                align-items: center;
                border-radius: 12px 30px 30px 0;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                padding-top: 3px;
              }
            }
          }
        }
      }
    }
  }
}
