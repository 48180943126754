@use "../global/" as g;

.p-company{
  padding-top: 40px;
  padding-bottom: 20px;
  @include g.mq{
    padding-top: 60px;
  }
  @include g.m(media){
    .o-page_link{
      padding-top: 10px;
    }
  }
  .o-title{
    margin-bottom: 10px;
    @include g.mq{
      margin-bottom: 20px;
    }
  }
  .o-btn{
    margin-top: 25px;
    padding-left: 15px;
  }
  .o-page_link{
    padding-top: 0;
    h2{
      color: g.$c_grn;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      @include g.mq{
        font-size: 28px;
      }
    }
    ul li{
      @include g.mq{
        max-width: 49%;
        &:nth-child(2){
          margin-right: 0;
        }
        p{
          max-width: 340px;
        }
      }
    }
  }
  @include g.e(contact){
    background: #F2F8F2;
    border-radius: 12px;
    padding: 20px 20px 30px;
    margin-bottom: 30px;
    @include g.mq{
      margin-bottom: 40px;
    }
    @include g.mq_m{
      padding: 40px 50px 50px;
    }
    > p{
      font-weight: 600;
      color: g.$c_grn;
      margin-bottom: 20px;
      text-align: center;
      line-height: 1.8;
      @include g.mq{
        font-size: 15px;
        margin-bottom: 30px;
      }
    }
  }
  @include g.e(data){
    background: #F2F8F2;
    border-radius: 12px;
    padding: 20px 20px 30px;
    margin-bottom: 30px;
    @include g.mq{
      margin-bottom: 40px;
    }
    @include g.mq_m{
      padding: 35px 50px 50px;
    }
    @include g.m(active){
      .o-btn{
        display: none;
      }
    }
    @include g.m(sp){
      padding-bottom: 10px;
      @include g.mq_m{
        padding-bottom: 25px;
      }
      dl{
        &:nth-of-type(10){
          dd p{
            margin-bottom: 10px;
          }
        }
        &:before{
          display: none;
        }
        dt:before{
          @include g.mq{
            display: none;
          }
        }
        dd{
          font-weight: normal;
          color: g.$c_black;
          h3{
            font-weight: bold;
            color: g.$c_grn;
          }
          p span{
            display: inline;
          }
          span{
            display: block;
            color: #969696;
            font-size: 12px;
            @include g.mq{
              font-size: 14px;
            }
          }
          ul{
            span{
              display: inline;
              color: g.$c_grn;
            }
          }
        }
      }
    }
    .o-btn{
      max-width: 100%;
      border-radius: 8px;
      &:before{
        position: static;
        margin-right: 20px;
        transform:rotate(90deg);
      }
    }
    dl{
      margin-bottom: 20px;
      position: relative;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      &:last-of-type{
        &:before{
          display: none;
        }
        dt:before{
          @include g.mq{
            display: none;
          }
        }
      }
      &:before{
        content: "";
        width: 4px;
        height: 20px;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%); /* Safari用 */
        transform: translateX(-50%);
        bottom: -20px;
        background: #4CB951;
        @include g.mq{
          display: none;
        }
      }
      dt{
        border-radius: 12px 12px 0 0;
        background: #DAF7DA;
        padding: 15px 20px;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        font-weight: bold;
        color: #4CB951;
        position: relative;
        @include g.mq{
          width: 260px;
          border-radius: 12px 0 0 12px;
          &:before{
            content: "";
            width: 4px;
            height: 20px;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%); /* Safari用 */
            transform: translateX(-50%);
            bottom: -20px;
            background: #4CB951;
          }
          &:after{
            position: absolute;
            right: -10px;
            top: 50%;
            -webkit-transform: translateY(-50%); /* Safari用 */
            transform: translateY(-50%);
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #DAF7DA;
            border-right: 0;
          }
        }
      }
      dd{
        background: #fff;
        border-radius: 0 0 12px 12px;
        padding: 15px 20px;
        color: g.$c_grn;
        font-weight: bold;
        @include g.mq{
          border-radius: 0 12px 12px 0;
          width : -webkit-calc(100% - 260px) ;
          width : calc(100% - 260px) ;
          padding-left: 40px;
        }
      }
    }
  }
  @include g.e(media){
    background: #F2F8F2;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 30px;
    @include g.mq{
      margin-bottom: 40px;
    }
    @include g.mq_m{
      padding: 40px 50px 45px;
    }
    dl{
      padding-bottom: 15px;
      border-bottom: 1px solid #D5FCD5;
      margin-bottom: 15px;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
      }
      dt{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        background: #4CB951;
        color: #fff;
        height: 30px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 3px;
        border-radius: 20px;
        max-width: 120px;
        @include g.mq{
          max-width: inherit;
          min-width: 120px;
          margin-bottom: 0;
        }
      }
      dd{
        color: g.$c_grn;
        @include g.mq{
          padding-top: 5px;
          padding-left: 20px;
          font-size: 15px;
        }
        a{
          color: #4CB951;
          font-weight: 500;
        }
        h3{
          font-weight: bold;
          @include g.m(sp){
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  @include g.e(title){
    margin-bottom: 20px;
    @include g.mq{
      margin-bottom: 30px;
    }
    p{
      font-weight: bold;
    }
    h2{
      line-height: 1.2;
      font-size: 18px;
      font-weight: bold;
      color: g.$c_grn;
      padding-bottom: 8px;
      border-bottom: 1px solid #eee;
      margin-bottom: 15px;
      @include g.mq{
        font-size: 28px;
      }
    }
  }
  @include g.e(case){
    background: #F2F8F2;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 30px;
    @include g.mq{
      margin-bottom: 40px;
    }
    @include g.mq_m{
      padding: 35px 50px 40px;
    }
    dl{
      padding-bottom: 10px;
      border-bottom: 1px solid #D5FCD5;
      margin-bottom: 15px;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
      }
      dt{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        background: #4CB951;
        color: #fff;
        height: 30px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 3px;
        border-radius: 20px;
        max-width: 120px;
        @include g.mq{
          max-width: inherit;
          min-width: 120px;
          margin-bottom: 0;
        }
      }
      dd{
        font-weight: bold;
        color: g.$c_grn;
        @include g.mq{
          padding-left: 20px;
          font-size: 15px;
        }
      }
    }
  }
  @include g.e(text){
    background: #F2F8F2;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 30px;
    @include g.mq{
      margin-bottom: 40px;
    }
    @include g.mq_m{
      padding: 35px 50px 40px;
    }
    > p{
      color: g.$c_grn;
      line-height: 1.8;
      font-weight: 600;
      margin-bottom: 10px;
      @include g.mq{
        margin-bottom: 20px;
        font-size: 15px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
