@use "../global/" as g;

.o-title {
  margin-bottom: 15px;
  @include g.mq{
    margin-bottom: 30px;
  }
  p{
    display: -webkit-flex; /* Safari用 */
    display: flex;
    -webkit-justify-content: center; /* Safari用 */
    justify-content: center;
    -webkit-align-items: center; /* Safari用 */
    align-items: center;
    max-width: 190px;
    margin: 0 auto 15px;
    line-height: 1;
    height: 30px;
    font-size: 16px;
    position: relative;
    border-radius: 20px;
    background: #fff;
    color: #E0AA5D;
    font-weight: bold;
    padding-top: 5px;
    @include g.mq{
      max-width: 215px;
      height: 40px;
      font-size: 20px;
    }
    &:after{
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-top: 6px solid #fff;
      border-bottom: 0;
      position: absolute;
      bottom: -6px;
      left: 50%;
      -webkit-transform: translateX(-50%); /* Safari用 */
      transform: translateX(-50%);
    }
  }
  h2{
    text-align: center;
    color: g.$c_grn;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    @include g.mq{
      font-size: 28px;
    }
  }
}
