/*
 * 色の設定
 */

$c_black: #414141;
$c_grn: #388E3C;
$c_ore: #DD9630;

//ブレイクポイント
$breakpoint: 768px;
$breakpoint_m: 1024px;
$breakpoint_mm: 1180px;
$breakpoint_l: 1260px;
$breakpoint_ll: 1366px;
$breakpoint_lll: 1440px;
$breakpoint_max: 1920px;
