@use "../global/" as g;

.p-qa{
  padding-bottom: 10px;
  @include g.e(link){
    background: #DAF7DA;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    @include g.mq{
      padding-top: 60px;
      padding-bottom: 50px;
      margin-bottom: 40px;
    }
    .o-btn{
      margin-bottom: 20px;
    }
    ul{
      max-width: 960px;
      margin: 0 auto;
      text-align: center;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        li{
          width: 33.3%;
        }
      }
    }
  }
  @include g.e(title){
    h2{
      color: g.$c_grn;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;
      @include g.mq{
        padding-bottom: 5px;
        font-size: 28px;
        margin-bottom: 40px;
      }
    }
  }
  @include g.e(list){
    ul{
      margin-bottom: 40px;
      @include g.mq{
        margin-bottom: 60px;
      }
      li{
        margin-bottom: 30px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
        padding: 20px 0 30px;
        @include g.mq{
          padding-bottom: 40px;
        }
        dl{
          dt{
            position: relative;
            margin-bottom: 30px;
            padding-left: 100px;
            padding-right: 20px;
            color: g.$c_grn;
            font-size: 15px;
            font-weight: bold;
            @include g.mq{
              padding-left: 130px;
              font-size: 18px;
              padding-right: 45px;
            }
            p{
              padding-top: 8px;
            }
            span{
              position: absolute;
              left: 0;
              height: 40px;
              width: 80px;
              border-radius: 0 20px 20px 0;
              background: g.$c_ore;
              color: #fff;
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              font-size: 20px;
              font-weight: bold;
              padding-top: 5px;
              @include g.mq{
                width: 115px;
              }
            }
          }
          dd{
            position: relative;
            padding-left: 100px;
            padding-right: 20px;
            @include g.mq{
              padding-left: 130px;
              padding-right: 45px;
            }
            span{
              position: absolute;
              left: 0;
              height: 40px;
              width: 80px;
              border-radius: 0 20px 20px 0;
              background: #4CB951;
              color: #fff;
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              font-size: 20px;
              font-weight: bold;
              padding-top: 5px;
              @include g.mq{
                width: 115px;
              }
            }
          }
        }
      }
    }
  }
}
