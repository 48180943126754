@use "../global/" as g;

.l-header{
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(000, 000, 000, 0.16);
  @include g.mq_m(){
    height: 120px;
  }
  @include g.e(content){
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    height: 60px;
    z-index: 200;
    @include g.mq_m(){
      height: 120px;
    }
    @include g.e(ham){
      width: 32px;
      height: 24px;
      position: absolute;
      right: 15px;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 300;
      @include g.mq_m{
        display: none;
      }
      .is-box{
        position: relative;
        height: 24px;
      }
      span{
        width: 32px;
        height: 2px;
        background-color: g.$c_grn;
        position: absolute;
        left: 0;
        transition-duration:1s;
        &:nth-child(1){
          top: 0;
        }
        &:nth-child(2){
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &:nth-child(3){
          bottom: 0;
        }
      }
    }
    @include g.e(list){
      position: absolute;
      right: 60px;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      width: 120px;
      text-align: right;
      @include g.mq_m{
        width: 800px;
        right: 18px;
      }
      .is-top{
        display: none;
        margin-bottom: 12px;
        @include g.mq_m(){
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
        }
        .is-text{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          li{
            margin-right: 25px;
            font-size: 14px;
            font-weight: bold;
          }
        }
        .is-lang{
          height: 23px;
          background: #E8FFF7;
          border-radius: 24px;
          text-align: center;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          width: 100px;
          padding: 2px;
          a{
            color: #44B38A;
            font-size: 14px;
            font-weight: bold;
            width: 50%;
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            text-align: center;
            height: 23px;
            padding-bottom: 4px;
            @include g.m(active){
              background: #179451;
              color: #fff;
              height: 19px;
              border-radius: 24px;
              padding-bottom: 0;
            }
          }
        }
        .is-cart{
          width: 22px;
          margin-left: 15px;
        }
      }
      .is-bottom{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .is-list{
          display: none;
          @include g.mq_m(){
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
          }
          li{
            margin-right: 35px;
            a{
              color: #969696;
              font-size: 14px;
              font-weight: bold;
              @include g.mq_l(){
                font-size: 16px;
              }
            }
          }
        }
        .is-btn{
          width: 120px;
          @include g.mq(){
            width: 175px;
          }
          .o-btn{
            max-width: 120px;
            height: 27px;
            font-size: 11px;
            @include g.mq_m(){
              max-width: 175px;
              height: 40px;
              font-size: 16px;
            }
            &:before{
              width: 19px;
              height: 19px;
              background-size: 5px;
              left: 6px;
              @include g.mq_m(){
                width: 28px;
                height: 28px;
                background-size: 7px;
              }
            }
          }
        }
      }
    }
    @include g.e(logo){
      position: absolute;
      display: block;
      width: 113px;
      left: 12px;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      @include g.mq_m{
        left: 18px;
        width: 200px;
        top: 65%;
      }
      p{
        margin-top: 5px;
        font-size: 10px;
        font-weight: bold;
        display: block;
        transform: scale(0.8);
        transform-origin: 0 50%;
        color: g.$c_grn;
        width: 120%;
        text-align: center;
        line-height: 1;
        @include g.mq_mm(){
          margin-top: 0;
          width: 100%;
          font-size: 14px;
          top: 65%;
          -webkit-transform: translateY(-50%); /* Safari用 */
          transform: translateY(-50%);
          left: 210px;
          position: absolute;
          text-align: left;
        }
      }
    }
  }
  @include g.e(list){
    display: none;
    position: fixed;
    height: -webkit-fill-available;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    @include g.e(bg){
      background-color: #DAF7DA;
      z-index: 10000;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: -webkit-fill-available;
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    @include g.e(ham){
      position: fixed;
      top: 18px;
      right: 18px;
      z-index: 20000;
      width: 32px;
      height: 24px;
      cursor: pointer;
      .is-box{
        position: relative;
        height: 24px;
      }
      span{
        width: 32px;
        height: 2px;
        background-color: g.$c_grn;
        position: absolute;
        left: 0;
        transition-duration:1s;
        &:nth-child(1){
          top: 0;
        }
        &:nth-child(3){
          bottom: 0;
        }
      }
    }
    @include g.e(content){
      position: relative;
      z-index: 15000;
      width: 100vw;
      height: 100vh;
      background: #DAF7DA;
      display: -webkit-flex; /* Safari用 */
      display: flex;
      -webkit-justify-content: center; /* Safari用 */
      justify-content: center;
      -webkit-align-items: center; /* Safari用 */
      align-items: center;
      text-align: center;
      .is-lang{
        margin: 30px auto 0;
        height: 30px;
        background: #E8FFF7;
        border-radius: 24px;
        text-align: center;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        max-width: 126px;
        padding: 2px;
        a{
          color: #44B38A;
          font-size: 17px;
          font-weight: bold;
          width: 50%;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          text-align: center;
          height: 26px;
          @include g.m(active){
            background: #179451;
            color: #fff;
            border-radius: 24px;
          }
        }
      }
      .o-btn{
        max-width: 175px;
      }
      ul{
        margin-bottom: 30px;
        li{
          margin-bottom: 20px;
          a{
            color: g.$c_grn;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }
}


.active_line1{
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration:1s;
  top: 10px !important;
  border-radius: 3px;
}

.active_line3{
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 10px !important;
  right: -1px;
  border-radius: 3px;
}
