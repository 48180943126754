@use "../global/" as g;

.p-top{
  @include g.m(en){
    .p-top__kv__content h2::after{
      background-image: url(../images/en/kv_img01.png);
      width: 67px;
      right: 5px;
      top: -60px;
      @include g.mq{
        width: 161px;
        height: 217px;
        right: -161px;
        top: -110px;
      }
    }
    .p-top__news ul::before{
      background-image: url(../images/en/kv_img02.png);
      height: 94px;
      top: -86px;
      @include g.mq{
        width: 124px;
        height: 233px;
        left: 36%;
        top: -220px;
      }
      @include g.mq_lll{
        left: 32%;
      }
    }
    .p-top__content__list{
      .o-btn{
        height: 48px;
        line-height: 1.1;
      }
      > ul > li{
        dl dd span{
          text-align: center;
        }
        &:nth-child(2){
          dl dd span{
            @include g.mq{
              max-width: 280px;
            }
          }
        }
      }
    }
    .p-top__content__case ul li dl dt{
      font-weight: 600;
      @include g.mq_l{
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .p-top__content__read dl dt{
      @include g.mq_m{
        font-size: 24px;
      }
    }
    .p-top__kv__content{
      @include g.mq{
        width: 56%;
      }
      h2{
        line-height: 1.3;
        @include g.mq{
          line-height: 1.1;
          font-size: 44px;
          &:after{

          }
          span{
            font-size: 36px;
          }
        }
      }
    }
  }
  @include g.e(event){
    background: #F8F5D4;
    padding-bottom: 30px;
    @include g.mq{
      padding-bottom: 45px;
    }
    .o-title{
      position: relative;
      top: -15px;
      @include g.mq{
        top: -20px;
      }
    }
  }
  @include g.e(content){
    background: url(../images/top/top_bg.png) top center no-repeat #F2F8F2;
    background-size: 100%;
    padding-top: 15px;
    padding-bottom: 30px;
    @include g.e(list){
      > ul > li{
        margin-bottom: 30px;
        dl{
          background: #DAF7DA;
          border-radius: 12px;
          padding: 20px;
          margin-bottom: 20px;
          @include g.mq{
            padding: 20px 0 20px 30px;
            margin-right: 30px;
            display: -webkit-flex; /* Safari用 */
            display: flex;
          }
          dd{
            margin-top: 20px;
            position: relative;
            @include g.mq{
              margin-top: 0;
              width: 51%;
              img{
                margin-top: -80px;
                margin-left: 30px;
              }
            }
            span{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              max-width: 200px;
              margin: 20px auto 0;
              line-height: 1;
              height: 30px;
              font-size: 12px;
              position: relative;
              border-radius: 4px;
              background: #fff;
              color: #4CB951;
              font-weight: bold;
              padding-top: 5px;
              @include g.mq{
                max-width: 240px;
                left: 30px;
              }
              &:after{
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                border-bottom: 5px solid #fff;
                border-top: 0;
                position: absolute;
                top: -5px;
                left: 50%;
                -webkit-transform: translateX(-50%); /* Safari用 */
                transform: translateX(-50%);
              }
            }
          }
          dt{
            font-size: 12px;
            @include g.mq{
              font-size: 16px;
              width: 49%;
            }
            h3{
              font-size: 18px;
              font-weight: bold;
              color: g.$c_grn;
              margin-bottom: 10px;
              line-height: 1.4;
              @include g.mq{
                font-size: 28px;
              }
            }
          }
        }
        .is-btn{
          max-width: 580px;
          margin: 0 auto;
          text-align: center;
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          li{
            @include g.mq{
              width: 48%;
              margin-right: 4%;
            }
            &:nth-child(2n){
              margin-top: 20px;
              @include g.mq{
                margin-top: 0;
                margin-right: 0;
              }
            }
            p{
              height: 30px;
              color: #4CB951;
              font-size: 12px;
              font-weight: bold;
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              border-radius: 4px;
              background: #fff;
              max-width: 240px;
              margin: 0 auto 15px;
              position: relative;
              &:after{
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-top: 6px solid #fff;
                border-bottom: 0;
                position: absolute;
                bottom: -6px;
                left: 50%;
                -webkit-transform: translateX(-50%); /* Safari用 */
                transform: translateX(-50%);
              }
              img{
                max-width: 14px;
                margin-right: 8px;
              }
            }
          }
        }
        .is-label{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          max-width: 190px;
          margin-bottom: 15px;
          margin-left: auto;
          margin-right: auto;
          line-height: 1;
          height: 30px;
          font-size: 16px;
          position: relative;
          border-radius: 20px;
          background: #fff;
          color: #E0AA5D;
          font-weight: bold;
          padding-top: 5px;
          @include g.mq{
            margin-left: 0;
            margin-bottom: 20px;
            max-width: 215px;
            height: 40px;
            font-size: 20px;
          }
          &:after{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-top: 6px solid #fff;
            border-bottom: 0;
            position: absolute;
            bottom: -6px;
            left: 50%;
            -webkit-transform: translateX(-50%); /* Safari用 */
            transform: translateX(-50%);
          }
        }
      }
    }
    @include g.e(case){
      h3{
        font-size: 16px;
        font-weight: bold;
        color: g.$c_grn;
        line-height: 1.8;
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 70px;
        text-align: center;
        @include g.mq{
          padding-bottom: 100px;
          font-size: 24px;
          margin-bottom: 50px;
        }
        &:after{
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0px;
          -webkit-transform: translateX(-50%); /* Safari用 */
          transform: translateX(-50%);
          display: block;
          width: 60px;
          height: 60px;
          background: url(../images/common/arw_down.png) center no-repeat g.$c_ore;
          background-size: 18px;
          border-radius: 50%;
          @include g.mq{
            background-size: 25px;
            width: 80px;
            height: 80px;
          }
        }
      }
      ul{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        li{
          max-width: 316px;
          margin: 0 auto 30px;
          position: relative;
          border: 2px solid #fff;
          border-radius: 12px;
          overflow: hidden;
          @include g.mq{
            margin-bottom: 50px;
            width: 32%;
            margin-left: 0;
            margin-right: 2%;
            &:nth-child(3n){
              margin-right: 0;
            }
          }
          dl{
            dt{
              background: #F2F8F2;
              font-size: 14px;
              padding: 15px 25px 10px;
              @include g.mq_l{
                font-size: 20px;
                padding-left: 45px;
                padding-right: 45px;
              }
            }
          }
          span{
            position: absolute;
            top: 0;
            left: 0;
            background: #4CB951;
            height: 40px;
            width: 118px;
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            border-radius: 12px 30px 30px 0;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            padding-top: 5px;
          }
        }
      }
    }
    @include g.e(read){
      margin-bottom: 20px;
      @include g.mq{
        margin-bottom: 5px;
      }
      dl{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
        }
        dd{
          @include g.mq{
            width: 53%;
          }
        }
        dt{
          font-size: 18px;
          font-weight: bold;
          line-height: 1.7;
          margin-bottom: 20px;
          color: g.$c_grn;
          @include g.mq{
            margin-bottom: 0;
            width: 47%;
          }
          @include g.mq_m{
            font-size: 28px;
          }
        }
      }
    }
  }
  @include g.e(news){
    padding: 0 4% 20px;
    @include g.mq_mm{
      padding-left: 40px;
      padding-right: 40px;
    }
    @include g.m(under){
      padding: 30px 0 0;
      @include g.mq{
        padding-top: 50px;
      }
      ul:before{
        display: none;
      }
      .p-top__news__content{
        margin-bottom: 0;
      }
    }
    @include g.e(content){
      background: #F2F8F2;
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 20px;
      @include g.mq{
        padding: 50px;
        margin-bottom: 40px;
      }
      .o-title{
        margin-bottom: 15px;
      }
      .is-btn{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
        }
        a{
          @include g.mq{
            margin-right: 1%;
          }
        }
        a:last-child{
          margin-top: 20px;
          @include g.mq{
            margin-top: 0;
            margin-right: auto;
            margin-left: 1%;
          }
        }
      }
      .is-list{
        margin-top: 0;
        margin-bottom: 20px;
        padding: 0;
        @include g.mq{
          margin-bottom: 40px;
        }
        li{
          border-bottom: 1px solid #B7F4DE;
        }
      }
    }
    ul{
      max-width: 1000px;
      position: relative;
      margin: -15px auto 0;
      background: #F2F8F2;
      border-radius: 12px;
      padding: 10px 20px;
      @include g.mq{
        padding: 20px 50px;
      }
      &:before{
        content: "";
        width: 50px;
        height: 106px;
        display: block;
        position: absolute;
        left: 30%;
        top: -95px;
        -webkit-transform: translateX(-50%); /* Safari用 */
        transform: translateX(-50%);
        background: url(../images/top/kv_img02.png) center no-repeat;
        background-size: 100%;
        @include g.mq{
          left: 45%;
          top: -205px;
          width: 104px;
          height: 220px;
        }
      }
      li{
        padding: 8px 0;
        @include g.mq{
          padding-top: 15px;
          padding-bottom: 15px;
        }
        &:nth-child(2){
          dl dt > a{
            background: #FBC02D;
          }
        }
        dl{
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          dd{
            color: #969696;
            font-size: 11px;
            font-weight: bold;
            margin-bottom: 5px;
            @include g.mq{
              margin-bottom: 0;
              font-size: 12px;
              width: 115px;
            }
          }
          dt{
            @include g.mq{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              width : -webkit-calc(100% - 115px) ;
              width : calc(100% - 115px) ;
            }
            > a{
              background: #4CB951;
              height: 28px;
              width: 120px;
              font-size: 12px;
              font-weight: bold;
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              color: #fff;
              border-radius: 20px;
              @include g.mq{
                margin-right: 25px;
              }
            }
            h3 a{
              font-size: 13px;
              font-weight: bold;
              color: g.$c_grn;
              @include g.mq{
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  @include g.e(kv){
    background: url(../images/top/kv.png) center no-repeat;
    background-size: cover;
    height: 240px;
    position: relative;
    @include g.mq{
      height: 377px;
    }
    @include g.e(content){
      position: absolute;
      width: 60%;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      @include g.mq{
        width: 48%;
      }
      h2{
        font-size: 26px;
        font-weight: bold;
        color: g.$c_grn;
        position: relative;
        display: inline-block;
        @include g.mq{
          font-size: 36px;
        }
        span{
          display: block;
          font-size: 18px;
          @include g.mq{
            font-size: 28px;
          }
        }
        &:after{
          content: "";
          width: 90px;
          height: 90px;
          display: block;
          position: absolute;
          right: -35px;
          top: -85px;
          background: url(../images/top/kv_img01.png) center no-repeat;
          background-size: 100%;
          @include g.mq{
            right: -170px;
            top: -130px;
            width: 194px;
            height: 193px;
          }
        }
      }
    }
  }
}
