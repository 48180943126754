@use "../global/" as g;

.p-tokyo_plus{
  padding-top: 30px;
  padding-bottom:50px;
  @include g.mq{
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @include g.e(content){
    padding: 20px;
    border-radius: 12px;
    background: #F2F8F2;
    @include g.mq_m{
      padding: 40px 50px;
    }
    > dl{
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 30px;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      &:nth-child(3){
        dd h3{
          margin-bottom: 0;
        }
      }
      &:nth-child(4){
        dd{
          font-size: 12px;
          @include g.mq{
            font-size: 14px;
          }
          h3{
            margin-bottom: 0;
            margin-top: 15px;
            &:first-child{
              margin-top: 0;
            }
          }
        }
      }
      &:nth-child(5){
        dd h3{
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
      &:nth-child(6){
        dt p{
          margin-top: 5px;
        }
      }
      &:nth-child(7){
        dt p{
          margin-top: 5px;
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
      > dt{
        border-radius: 12px 12px 0 0;
        background: #4CB951;
        padding: 20px;
        position: relative;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        @include g.mq{
          width: 260px;
          padding-left: 25px;
          padding-right: 25px;
          border-radius: 12px 0 0 12px;
          &:after{
            content: "";
            display: block;
            position: absolute;
            right: -10px;
            top: 50%;
            -webkit-transform: translateY(-50%); /* Safari用 */
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #4CB951;
            border-right: 0;
          }
        }
        span{
          display: block;
          width: 100%;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          margin-top: auto;
          text-align: center;
          @include g.mq{
            font-size: 14px;
          }
        }
        p{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          width: 100%;
          max-width: 190px;
          margin: auto;
          line-height: 1;
          height: 30px;
          font-size: 14px;
          position: relative;
          border-radius: 20px;
          background: #F2F8F2;
          color: #4CB951;
          font-weight: bold;
          padding-top: 5px;
          @include g.mq{
            max-width: 215px;
            height: 35px;
          }
        }
      }
      > dd{
        border-radius: 0 0 12px 12px;
        padding: 20px;
        background: #fff;
        @include g.mq{
          width : -webkit-calc(100% - 260px) ;
          width : calc(100% - 260px) ;
        }
        @include g.mq_m{
          padding: 40px;
        }
        a{
          color: g.$c_grn;
          text-decoration: underline;
          &:after{
            content: "";
            display: inline-block;
            vertical-align: baseline;
            width: 12px;
            height: 12px;
            background: url(../images/common/icon_link.png) center no-repeat;
            background-size: 100%;
          }
        }
        ul li{
          font-size: 12px;
          color: #969696;
          font-weight: 600;
          @include g.mq{
            font-size: 14px;
          }
        }
        h2{
          color: g.$c_grn;
          font-weight: bold;
          font-size: 16px;
          margin-top: 15px;
          @include g.mq{
            font-size: 20px;
          }
          &:first-child{
            margin-top: 0;
          }
        }
        h3{
          color: g.$c_grn;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }
}
