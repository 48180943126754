@use "../global/" as g;

.p-about{
  padding-top: 30px;
  padding-bottom: 40px;
  @include g.mq{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include g.m(more){
    padding-bottom: 20px;
    .p-about__effect_check{
      background: #F2F8F2;
      padding-top: 60px;
    }
  }
  @include g.m(what){
    .p-about__effect_flow > ul > li{
      padding-top: 10px;
      @include g.m(sp){
        position: relative;
        padding-bottom: 90px;
        @include g.mq{
          padding-bottom: 40px;
        }
        &:before{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: block;
          background: url(../images/about/list_bg_bottom.png) bottom center no-repeat;
          background-size: 100%;
          height: 0;
          padding-bottom: 27.5%;
          z-index: 0;
        }
      }
      h3{
        padding-left: 110px;
        color: g.$c_grn;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        @include g.mq{
          font-size: 24px;
        }
      }
      dl{
        dt{
          @include g.mq{
            width: -webkit-calc(100% - 372px);
            width: calc(100% - 372px);
            padding-left: 25px;
            padding-right: 0;
          }
        }
        dd{
          max-width: 100%;
          margin-bottom: 20px;
          @include g.mq{
            margin-bottom: 0;
          }
        }
      }
    }
    .p-about__effect_list{
      margin-top: 0;
      > ul > li dl dt{
        color: g.$c_grn;
        font-weight: bold;
        @include g.mq{
          padding-top: 30px;
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
        }
      }
    }
    .p-about__effect_check{
      h3{
        text-align: center;
        margin-top: 10px;
      }
      .is-img{
        margin-top: 20px;
        @include g.mq{
          margin-top: 40px;
        }
        dl{
          dd{
            img:last-child{
              margin-top: 20px;
            }
          }
          dt{
            font-size: 14px;
            @include g.mq{
              font-size: 16px;
            }
            p{
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  @include g.e(island){
    h2{
      color: g.$c_grn;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
      @include g.mq{
        font-size: 28px;
      }
    }
    ul{
      li{
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        a{
          color: g.$c_ore;
          font-weight: bold;
          &:before{
            content: "";
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: url(../images/common/arw_wh.png) center no-repeat g.$c_ore;
            background-size: 5px;
            @include g.mq{
              width: 28px;
              height: 28px;
              background-size: 7px;
            }
          }
        }
      }
    }
  }
  @include g.e(tour){
    margin-top: 40px;
    > dl{
      border-radius: 12px;
      overflow: hidden;
      > dd{
        background: #F8F5D4;
        padding: 20px;
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -webkit-justify-content: center; /* Safari用 */
          justify-content: center;
          -webkit-align-items: center; /* Safari用 */
          align-items: center;
          padding-top: 25px;
          padding-bottom: 30px;
        }
        .o-btn{
          @include g.mq{
            margin-left: 0;
            margin-right: 0;
          }
        }
        .o-btn:nth-child(2){
          margin-top: 20px;
          @include g.mq{
            margin-top: 0;
            margin-left: 20px;
          }
        }
      }
      > dt{
        background: url(../images/top/event_bg.png) left center no-repeat g.$c_grn;
        background-size: 100%;
        height: 150px;
        max-width: 100%;
        padding-left: 22%;
        text-align: left;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        @include g.mq{
          background-image: url(../images/top/event_bg_pc.png);
          background-size: 1000px;
          height: 192px;
          padding-left: 100px;
        }
        dl{
          color: #fff;
          dt{
            font-size: 24px;
            font-weight: bold;
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            margin-bottom: 10px;
            line-height: 1;
            @include g.mq{
              font-size: 45px;
              margin-bottom: 15px;
            }
            span{
              font-size: 14px;
              padding-left: 10px;
              @include g.mq{
                font-size: 18px;
              }
            }
          }
          dd{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            border-radius: 20px;
            background: #F2F8F2;
            font-size: 12px;
            color: #4CB951;
            padding: 6px 12px;
            text-align: center;
            font-weight: bold;
            @include g.mq{
              padding: 8px 20px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @include g.e(archive){
    margin-top: 30px;
    margin-bottom: 10px;
    @include g.mq{
      margin-top: 50px;
    }
    @include g.m(tokyo){
      margin-top: 0;
      h2{
        color: g.$c_grn;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 1;
        @include g.mq{
          font-size: 24px;
        }
      }
      > ul > li{
        @include g.mq{
          margin-bottom: 40px;
        }
      }
    }
    @include g.e(btn){
      margin-bottom: 30px;
      text-align: center;
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
      }
      .is-btn{
        width: 280px;
        margin-left: auto;
        margin-right: auto;
        @include g.mq{
          margin-left: 0;
          margin-right: 0;
        }
      }
      .is-btn:nth-child(2){
        margin-top: 20px;
        @include g.mq{
          margin-top: 0;
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
    > ul{
      @include g.mq{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      > li{
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
        margin-bottom: 30px;
        @include g.mq{
          width: 48%;
          margin-right: 4%;
          &:nth-child(2n){
            margin-right: 0;
          }
        }
        dl{
          dt{
            padding-bottom: 15px;
            .is-link{
              text-align: center;
              a{
                color: g.$c_ore;
                font-weight: bold;
                &:before{
                  content: "";
                  display: inline-block;
                  margin-right: 8px;
                  vertical-align: middle;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: url(../images/common/arw_wh.png) center no-repeat g.$c_ore;
                  background-size: 5px;
                  @include g.mq{
                    width: 28px;
                    height: 28px;
                    background-size: 7px;
                  }
                }
              }
            }
            .is-content{
              padding: 0 20px 10px;
              line-height: 1.7;
              @include g.mq{
                padding-left: 30px;
                padding-right: 30px;
              }
            }
            h3{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -webkit-justify-content: center; /* Safari用 */
              justify-content: center;
              -webkit-align-items: center; /* Safari用 */
              align-items: center;
              max-width: 90%;
              margin: -20px auto 25px;
              line-height: 1.3;
              height: 40px;
              font-size: 18px;
              position: relative;
              border-radius: 20px;
              background: #4CB951;
              color: #fff;
              font-weight: bold;
              padding-top: 5px;
              &:after{
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-top: 6px solid #4CB951;
                border-bottom: 0;
                position: absolute;
                bottom: -6px;
                left: 50%;
                -webkit-transform: translateX(-50%); /* Safari用 */
                transform: translateX(-50%);
              }
            }
          }
          dd{
            border-radius: 12px 12px 0 0;
            overflow: hidden;
            img{
              height: 205px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  @include g.e(what_last){
    margin-bottom: 30px;
    background: #DAF7DA;
    padding-bottom: 20px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    @include g.mq{
      padding-bottom: 0;
      margin-bottom: 50px;
    }
    img{
      margin: 20px auto 0;
      display: block;
      @include g.mq{
        margin-top: 0;
        position: absolute;
        bottom: 10px;
        right: 40px;
      }
    }
    dl{
      dt{
        padding: 50px 20px 10px;
        background: #F2F8F2;
        position: relative;
        color: g.$c_grn;
        @include g.mq{
          padding: 60px 30px 15px;
        }
        .is-check{
          position: absolute;
          top: 0;
          left: 0;
          background: #4CB951;
          height: 40px;
          width: 118px;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          align-items: center;
          border-radius: 12px 30px 30px 0;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          padding-top: 5px;
        }
        p{
          @include g.mq{
            padding-right: 230px;
          }
        }
      }
      dd{
        padding: 20px;
        background: #DAF7DA;
        font-size: 12px;
        @include g.mq{
          padding: 25px 30px;
        }
        p{
          @include g.mq{
            padding-right: 230px;
          }
        }
      }
    }
  }
  @include g.e(effect_flow){
    > ul > li{
      position: relative;
      margin-bottom: 45px;
      background: url(../images/common/list_bg.png) top left no-repeat #F2F8F2;
      background-size: 100%;
      border-radius: 12px;
      padding: 50px 20px 30px;
      @include g.mq{
        padding: 60px 60px 30px 30px;
      }
      .is-link{
        margin-top: 20px;
        position: relative;
        z-index: 2;
        ul{
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          li{
            background: #fff;
            border-radius: 12px;
            padding: 20px 20px 10px;
            margin-bottom: 20px;
            @include g.mq{
              width: 48%;
              margin-right: 4%;
              padding-left: 35px;
              padding-right: 35px;
              min-height: 240px;
              &:nth-child(2n){
                margin-right: 0;
              }
            }
            h4{
              color: g.$c_grn;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 15px;
              @include g.mq{
                font-size: 18px;
              }
              &:before{
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                background: url(../images/common/icon_check.png) center no-repeat;
                background-size: 16px;
              }
            }
          }
        }
      }
      dl{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
        }
        dt{
          @include g.mq{
            width : -webkit-calc(100% - 206px) ;
            width : calc(100% - 206px) ;
            padding-right: 35px;
          }
        }
        dd{
          margin: 10px auto 0;
          max-width: 150px;
          @include g.mq{
            max-width: 206px;
            margin-top: 0;
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
      &:after{
        content: "";
        position: absolute;
        left: 50%;
        bottom: -30px;
        -webkit-transform: translateX(-50%); /* Safari用 */
        transform: translateX(-50%);
        display: block;
        width: 60px;
        height: 60px;
        background: url(../images/common/arw_down.png) center no-repeat g.$c_ore;
        background-size: 18px;
        border-radius: 50%;
        z-index: 1;
        @include g.mq{
          background-size: 25px;
          bottom: -40px;
          width: 80px;
          height: 80px;
        }
      }
      .is-check{
        position: absolute;
        top: 0;
        left: 0;
        background: #4CB951;
        height: 40px;
        width: 118px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        border-radius: 12px 30px 30px 0;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        padding-top: 5px;
      }
      @include g.mq{
        margin-bottom: 60px;
      }
    }
  }
  @include g.e(pagination){
    margin-top: 30px;
    @include g.mq{
      margin-top: 40px;
    }
  }
  @include g.e(effect_check){
    border-radius: 12px;
    background: #F5F5F5;
    padding: 50px 20px 20px;
    position: relative;
    margin-bottom: 20px;
    @include g.mq{
      margin-bottom: 30px;
      padding: 50px 30px 30px;
    }
    @include g.m(archive){
      background: #F2F8F2;
      margin-bottom: 0;
      @include g.mq{
        padding-top: 60px;
      }
      .is-list{
        margin-top: 20px;
        li{
          font-size: 15px;
          color: g.$c_grn;
          padding-top: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #D5FCD5;
          &:before{
            content: "";
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            background: url(../images/common/icon_check.png) no-repeat;
            background-size: 100%;
          }
        }
      }
    }
    @include g.m(movie){
      background: #F2F8F2;
    }
    .is-movie{
      border-radius: 12px;
      overflow: hidden;
      > dl{
        > dt{
          background: #DAF7DA;
          padding: 20px;
          @include g.mq{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            .o-btn{
              margin-left: 60px;
            }
          }
          dl{
            display: -webkit-flex; /* Safari用 */
            display: flex;
            -webkit-justify-content: center; /* Safari用 */
            justify-content: center;
            -webkit-align-items: center; /* Safari用 */
            align-items: center;
            margin-bottom: 20px;
            @include g.mq{
              margin-left: auto;
              margin-bottom: 0;
            }
            dd{
              margin-right: 10px;
            }
            dt{
              color: g.$c_grn;
            }
          }
        }
        > dd{
          background: #fff;
          padding: 20px;
          @include g.mq{
            padding: 40px;
          }
          dl{
            @include g.mq{
              display: -webkit-flex; /* Safari用 */
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
            }
            dt{
              margin-bottom: 20px;
              @include g.mq{
                width: 40%;
                padding-right: 40px;
                margin-bottom: 0;
              }
            }
            dd{
              @include g.mq{
                width: 60%;
              }
            }
          }
        }
      }
    }
    .is-img{
      dl{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        dt{
          font-size: 12px;
          line-height: 1.7;
          margin-bottom: 15px;
          @include g.mq{
            margin-bottom: 0;
            width: 60%;
            font-size: 14px;
            padding-right: 30px;
          }
          .o-btn{
            margin-top: 20px;
            @include g.mq{
              margin-top: 40px;
            }
          }
        }
        dd{
          @include g.mq{
            width: 40%;
          }
        }
      }
    }
    h3{
      color: g.$c_grn;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 18px;
      @include g.mq{
        margin-bottom: 20px;
        font-size: 24px;
      }
    }
    .is-bl{
      display: -webkit-flex; /* Safari用 */
      display: flex;
      -webkit-justify-content: center; /* Safari用 */
      justify-content: center;
      -webkit-align-items: center; /* Safari用 */
      align-items: center;
      max-width: 190px;
      margin: 0 auto 15px;
      line-height: 1;
      height: 30px;
      font-size: 16px;
      position: relative;
      border-radius: 20px;
      background: #fff;
      color: #E0AA5D;
      font-weight: bold;
      padding-top: 5px;
      @include g.mq{
        max-width: 215px;
        height: 40px;
        font-size: 20px;
      }
      &:after{
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-top: 6px solid #fff;
        border-bottom: 0;
        position: absolute;
        bottom: -6px;
        left: 50%;
        -webkit-transform: translateX(-50%); /* Safari用 */
        transform: translateX(-50%);
      }
    }
    .is-check{
      position: absolute;
      top: 0;
      left: 0;
      background: #4CB951;
      height: 40px;
      width: 118px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      border-radius: 12px 30px 30px 0;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      padding-top: 5px;
    }
    .is-logo{
      background: #fff;
      padding: 20px;
      text-align: center;
      border-radius: 12px;
      margin-bottom: 20px;
      @include g.mq{
        padding: 50px;
      }
    }
  }
  @include g.e(effect_list){
    margin-top: 30px;
    padding-bottom: 10px;
    @include g.mq{
      margin-top: 45px;
      padding-bottom: 20px;
    }
    > ul > li{
      background: #F2F8F2;
      margin-bottom: 25px;
      border-radius: 12px;
      dl{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        dd{
          img{
            border-radius: 12px;
            @include g.mq{
              border-radius: 0;
            }
          }
          @include g.mq{
            width: 41%;
          }
        }
        dt{
          padding: 45px 20px 20px;
          font-size: 12px;
          line-height: 1.7;
          position: relative;
          @include g.mq{
            font-size: 14px;
            width: 59%;
            padding-left: 30px;
            padding-right: 30px;
          }
          p{
            @include g.mq{
              max-width: 410px;
            }
          }
          h3{
            color: g.$c_grn;
            font-weight: bold;
            margin-bottom: 10px;
            line-height: 1;
            font-size: 14px;
            @include g.mq{
              margin-bottom: 15px;
              font-size: 18px;
            }
            &:before{
              content: "";
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
              width: 16px;
              height: 16px;
              background: url(../images/common/icon_check.png) no-repeat;
              background-size: 100%;
            }
          }
          span{
            position: absolute;
            top: 0;
            left: 0;
            background: #4CB951;
            height: 28px;
            width: 120px;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 12px 30px 30px 0;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            padding-top: 3px;
          }
        }
      }
    }
  }
  @include g.e(effect_title){
    margin-bottom: 20px;
    @include g.mq{
      margin-bottom: 30px;
    }
    p{
      font-size: 12px;
      margin-top: 10px;
      @include g.mq{
        margin-top: 15px;
        font-size: 16px;
      }
    }
    h2{
      border-bottom: 1px solid #eee;
      color: g.$c_grn;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.6;
      @include g.mq{
        font-size: 28px;
        padding-bottom: 5px;
      }
    }
  }
  @include g.e(effect_top){
    margin-bottom: 20px;
    @include g.mq{
      margin-bottom: 35px;
    }
    dl{
      padding: 20px;
      background: #F5F5F5;
      border-radius: 12px;
      @include g.mq{
        padding: 0;
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      dd{
        position: relative;
        text-align: center;
        margin-bottom: 20px;
        @include g.mq{
          width: 330px;
          margin-bottom: 0;
        }
        span{
          position: absolute;
          top: 2px;
          left: 2px;
          background: #4CB951;
          height: 40px;
          width: 118px;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          align-items: center;
          border-radius: 12px 30px 30px 0;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          padding-top: 5px;
        }
        img{
          border-radius: 12px;
          @include g.mq{
            border-radius: 12px 0 0 12px;
          }
        }
      }
      dt{
        font-size: 14px;
        @include g.mq{
          font-size: 18px;
          width : -webkit-calc(100% - 330px) ;
          width : calc(100% - 330px) ;
          padding: 20px 60px;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          align-items: center;
        }
        h3{
          text-align: center;
          color: g.$c_grn;
          width: 100%;
        }
      }
    }
  }
  @include g.e(title){
    border-radius: 12px;
    background: #F2F8F2;
    margin-bottom: 20px;
    @include g.mq{
      margin-bottom: 40px;
    }
    h2{
      padding: 15px 20px;
      font-size: 20px;
      font-weight: bold;
      color: g.$c_grn;
      @include g.mq{
        padding: 20px 30px;
        font-size: 28px;
      }
    }
  }
  @include g.e(list){
    > ul > li{
      margin-bottom: 25px;
      background: #fff;
      box-shadow: 0 3px 6px rgba(000, 000, 000, 0.16);
      border-radius: 12px;
      padding: 20px;
      @include g.mq{
        padding: 0;
      }
      dl{
        @include g.mq{
          display: -webkit-flex; /* Safari用 */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        dd{
          text-align: center;
          margin-bottom: 20px;
          @include g.mq{
            width: 330px;
            margin-bottom: 0;
          }
          img{
            border-radius: 12px;
            @include g.mq{
              border-radius: 12px 0 0 12px;
            }
          }
        }
        dt{
          @include g.mq{
            width : -webkit-calc(100% - 330px) ;
            width : calc(100% - 330px) ;
            padding: 15px 30px;
          }
          h3{
            font-size: 16px;
            font-weight: bold;
            color: g.$c_grn;
            margin-bottom: 10px;
            @include g.mq{
              font-size: 20px;
            }
            a{
              color: g.$c_grn;
            }
          }
          p{
            font-size: 12px;
            line-height: 1.7;
            @include g.mq{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
