@use "../global/" as g;

.o-breadcrumb{
  font-size: 12px;
  width: 100%;
  line-height: 1.5;
  background: #F2F8F2;
  padding: 10px 4%;
  position: relative;
  @include g.mq_mm{
    padding: 20px 40px;
  }
  ul{
    max-width: 1000px;
    margin: 0 auto;
    li{
      display: inline;
      padding-right: 3px;
      padding-bottom: 5px;
      color: g.$c_grn;
      a{
        font-weight: bold;
        color: g.$c_grn;
      }
    }
    li:before {
      font-weight: normal;
      content: ">";
      padding-right: 4px;
    }
    li:first-child:before{
      content: "";
      padding-right: 0;
    }
  }
}
