@use "../global/" as g;

html, body {
  font-family: 'Noto Sans Myanmar';
  color: g.$c_black;
}
body {
  overflow-x: hidden;
  line-height: 1.5;
  font-size: 14px;
  @include g.mq{
    font-size: 16px;
  }
  &.page-id-151 , &.parent-pageid-151 , &.post-type-archive-tour{
    .l-header__content__list .is-bottom .is-list li.is-tuor a{
      color: g.$c_grn;
    }
  }
  &.page-id-14 , &.parent-pageid-14 , &.post-type-archive-forest_bathing{
    .l-header__content__list .is-bottom .is-list li.is-about a{
      color: g.$c_grn;
    }
  }
  &.page-id-96{
    .l-header__content__list .is-bottom .is-list li.is-itswellness a{
      color: g.$c_grn;
    }
  }
  &.page-id-193{
    .l-header__content__list .is-bottom .is-list li.is-qa a{
      color: g.$c_grn;
    }
  }
}

.font_yugo{
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.font_yumin{
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

.font_hirasan{
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", Meiryo, メイリオ, sans-serif;
}

.font_hiramin {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
}

.font_hirakaku {
  font-family: "HiraKakuProN-W3","ヒラギノ角ゴ ProN W3","ヒラギノ角ゴ Pro W3","HiraKakuPro-W3","HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS Pgothic","Osaka",sans-serif,Helvetica, Helvetica Neue, Arial, Verdana;
}

.font_hel{
  font-family: Helvetica, Helvetica Neue,sans-serif,;
}

.font_meirio{
  font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

header, nav, main, footer, small, button, label {
  display: block;
}

a,button {
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
  color: g.$c_black;
}

button:hover, button:active{
  outline: 0;
  opacity: 0.8;
  text-decoration: none;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  border-radius: 0;
  outline: none;
}

i{
  font-style: normal;
}

a:hover, a:active{
  outline: 0;
  opacity: 0.8;
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #a5a5a5;
}
:-ms-input-placeholder {
  color: #a5a5a5;
}
::-moz-placeholder {
  color: #a5a5a5;
}
::-placeholder {
  color: #a5a5a5;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: #fff;
  border: none;
  box-shadow: 0 1px 1px rgba(000, 000, 000, 0.16);
  border-radius: 0;
  outline: none;
}
input[type="checkbox"] {
  cursor: pointer;
}
select {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.containner {
  padding-bottom: 40px;
  @include g.mq {
    padding-bottom: 80px;
  }
  @include g.m(pb_none) {
    padding-bottom: 0;
  }
}

.flex{
  display: flex;
}


.innerBox {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 4%;
  position: relative;
  @include g.mq_mm{
    padding: 0 40px;
  }
  &.is-h100{
    height: 100%;
  }
}

.min-620{
  min-height: 620px;
}

.mb-30{
  margin-bottom: 30px;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.left{
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.display_to_mq {
  @include g.mq {
    display: none;
  }
}

.display_to_mq_m {
  @include g.mq_m {
    display: none;
  }
}

.display_mq {
  display: none;
  &.is-inline{
    @include g.mq {
      display: inline;
    }
  }
  &.is-block{
    @include g.mq {
      display: block;
    }
  }
}

.display_mq_m {
  display: none;
  &.is-inline{
    @include g.mq_m {
      display: inline;
    }
  }
  &.is-block{
    @include g.mq_m {
      display: block;
    }
  }
}



.relative_wrap {
  position: relative;
}


.inline_block {
  display: inline-block;
}


.text_indent {
  padding-left: 1em;
  text-indent: -1em;
}

.c_pink {
  color: #f29c9f;
}

.post_cnt {
  iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

img.alignleft {
  display: block;
  margin-right: auto;
}

img.alignright {
  display: block;
  margin-left: auto;
}

input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}
main{
  min-height: calc(100vh - 60px);
  position: relative;
  z-index: 1;
  @include g.mq_m(){
    min-height: calc(100vh - 120px);
  }
}

.is-margin-header{
  margin-top: 60px;
  @include g.mq_m(){
    margin-top: 138px;
  }
}

#page__top{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1A1A1A;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  &:hover{
    cursor: pointer;
  }
  @include g.mq_mm(){
    right: 32px;
    bottom: 32px;
  }
  @include g.mq_ll{
    width: 95px;
    height: 95px;
  }
}


a[href^="tel:"] {
  @include g.mq{
    pointer-events: none;
  }
}

.is-fixed{
  width: 100%;
  height: 100%;
  position: fixed;
}

.is-iframe_wrap{
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  iframe {
   position: absolute;
   top: 0;
   right: 0;
   width: 100%;
   height: 100%;
  }
}
