@use "../global/" as g;

.o-page_link {
  padding-top: 20px;
  padding-bottom: 30px;
  @include g.mq{
    padding-top: 35px;
    padding-bottom: 40px;
  }
  ul{
    @include g.mq{
      display: -webkit-flex; /* Safari用 */
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    li{
      max-width: 320px;
      margin: 0 auto 20px;
      display: -webkit-flex; /* Safari用 */
      display: flex;
      -webkit-justify-content: center; /* Safari用 */
      justify-content: center;
      -webkit-align-items: center; /* Safari用 */
      align-items: center;
      text-align: center;
      padding: 0;
      width: 100%;
      @include g.mq{
        max-width: 32%;
        margin-left: 0;
        margin-right: 2%;
        margin-bottom: 0;
      }
      &:last-child{
        margin-bottom: 0;
        @include g.mq{
          margin-right: 0;
        }
      }
      a{
        color: #fff;
        width: 100%;
        display: block;
        padding: 20px;
        border-radius: 12px;
        background: g.$c_grn;
        @include g.mq{
          padding-top: 25px;
        }
      }
      h3{
        font-size: 16px;
        font-weight: bold;
      }
      p{
        display: -webkit-flex; /* Safari用 */
        display: flex;
        -webkit-justify-content: center; /* Safari用 */
        justify-content: center;
        -webkit-align-items: center; /* Safari用 */
        align-items: center;
        width: 100%;
        max-width: 190px;
        margin: 0 auto 12px;
        line-height: 1;
        height: 30px;
        font-size: 16px;
        position: relative;
        border-radius: 20px;
        background: #F2F8F2;
        color: #4CB951;
        font-weight: bold;
        padding-top: 5px;
        @include g.mq{
          max-width: 240px;
          height: 40px;
          font-size: 20px;
        }
        &:after{
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          border-top: 6px solid #F2F8F2;
          border-bottom: 0;
          position: absolute;
          bottom: -6px;
          left: 50%;
          -webkit-transform: translateX(-50%); /* Safari用 */
          transform: translateX(-50%);
        }
      }
    }
  }
}
